import { Button, CircularProgress, Dialog, Divider, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import axios from "../../api/axios";
import { queryClient } from "../../App";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { phoneRegex } from "../../utils/validationPattern";

// Change Phone Component
function ChangePhone(props) {
    // USESTATE & USEREF
    const [openOtp, setOpenOtp] = useState(false);
    const [requestOtp, setRequestOtp] = useState(false)
    const phoneRef = useRef();

    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm({
        mode: "onChange"
    });
    phoneRef.current = watch("phone", "");

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // QUERY & MUTATION
    const { isFetching: otpIsFetching, data: otpData, error: otpError, } = useQuery(["otp-edit-profile"], () => {
        return (
            axios.get(
                '/otp',
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        type: "signup",
                        phone: phoneRef.current
                    }
                })
        )
    },
        {
            enabled: requestOtp,
        }
    );
    const { mutate, isLoading, data, error } = useMutation((phone) => {
        return axiosPrivate.patch(`/profile/phone`, phone);
    });

    // HELPERS
    const getOtp = () => {
        setRequestOtp(true)
        setOpenOtp(true);
    }
    const onSubmit = (formData) => {
        mutate(formData);
    }
    const handleClick = () => {
        setOpenOtp(false);
    }

    // USEFFECT// USEEFFECT
    useEffect(() => {
        if (props.phone) {
            setValue("phone", props.phone);
        }
    }, [setValue, props]);

    useEffect(() => {
        if (otpData) {
            enqueueSnackbar(`Code sent to your phone!`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            queryClient.clear()
            setRequestOtp(false)
        }
    }, [enqueueSnackbar, otpData, data]);

    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully changed your phone.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOpenOtp(false);
            props.setOpen(false);
            props.refetch();
            queryClient.clear()
        }
    }, [data, enqueueSnackbar, props]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                    }
                );
            }
            setOpenOtp(true);
        }
        if (otpError) {
            enqueueSnackbar(
                otpError?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
            setRequestOtp(false);
        }
    }, [enqueueSnackbar, error, otpError]);

    // RENDER
    return (
        <Dialog open={props.open}>
            {!(openOtp && !otpIsFetching && !otpError) ?
                (<Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p: "5%"
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                            Change Your Phone
                        </Typography>
                        <Box component="form" sx={{ mt: 1 }}>
                            <Divider />
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: "Phone is required",
                                    pattern: {
                                        value: phoneRegex,
                                        message: "Invalid input",
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{
                                            mt: 1.5,
                                            mb: 1
                                        }}
                                        variant="outlined"
                                        label="Phone"
                                        fullWidth
                                        autoComplete="phone"
                                        error={!!errors?.phone}
                                        helperText={errors?.phone ? errors.phone.message : null}
                                        size="small"
                                        {...field}
                                    />
                                )}
                            />
                            <Divider />
                            <Box
                                sx={{
                                    my: 1,
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ":hover": { bgcolor: "#FFAA00" },
                                        backgroundColor: "#1c2526",
                                        color: "#fff",
                                    }}
                                    onClick={() => props.setOpen(false)}
                                >
                                    Back
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ":hover": { bgcolor: "primary.main" },
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                    }}
                                    onClick={() => getOtp()}
                                    disabled={otpIsFetching}
                                >
                                    Next
                                    {otpIsFetching && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "secondary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                ) : (
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                p: 2,
                            }}
                        >
                            <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: 'center' }}>
                                Enter a code
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                                <Divider />
                                <Typography sx={{ fontWeight: "medium", fontSize: 16, pt: 1 }}>
                                    Please fill the code sent to your phone number: {phoneRef.current} via SMS.
                                </Typography>
                                <Controller
                                    name="otp"
                                    control={control}
                                    rules={{
                                        required: "Code is required",
                                        minLength: 6,
                                        maxLength: 6
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            sx={{
                                                mt: 1.5,
                                                mb: 1
                                            }}
                                            fullWidth
                                            size="small"
                                            type="number"
                                            label="Code"
                                            autoComplete="otp"
                                            autoFocus
                                            helperText={errors?.otp ? errors?.otp?.message : ""}
                                            error={errors?.otp}
                                            {...field}
                                        />
                                    )}
                                />
                                <Button
                                    size="small"
                                    variant="text"
                                    type="text"
                                    sx={{
                                        ":hover": { bgcolor: "#fff" },
                                        backgroundColor: "#fff",
                                        color: "primary.main",
                                        variant: "body2",
                                        mt: 1
                                    }}
                                    onClick={() => {
                                        setRequestOtp(true)
                                    }}
                                    disabled={otpIsFetching}
                                >
                                    Resend OTP
                                    {otpIsFetching && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "secondary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                                <Divider />
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ":hover": { bgcolor: "#FFAA00" },
                                            backgroundColor: "#1c2526",
                                            color: "#fff",
                                        }}
                                        onClick={() => handleClick()}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ":hover": { bgcolor: "#1c2526" },
                                            backgroundColor: "#FFAA00",
                                            color: "#fff",
                                        }}
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        Submit
                                        {isLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "secondary.main",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                )}
        </Dialog>
    );
}
export default ChangePhone