import { Edit } from "@mui/icons-material";
import { Avatar, Badge, Box, IconButton, Tooltip } from "@mui/material";
import { BASE_URL, PROFILE_IMAGE_URL, VERSION } from "../../utils/config";

// Profile Picture Viewer Component
const ViewProfilePicture = ({ setToggle, imageSrc, viewEditIcon }) => {
    // EVENT HANDLER
    const handleEditClick = () => {
        setToggle(true);
    };

    // RENDER
    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", m:2 }}>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent= {
                    viewEditIcon && 
                    <IconButton
                        onClick={handleEditClick}
                    >
                        <Tooltip title="Change your profile picture">
                            <Edit
                                sx={{
                                    color: "secondary.main",
                                    bgcolor: "primary.paper",
                                    borderRadius: "50%",
                                }}
                            />
                        </Tooltip>
                    </IconButton>
                }
            >
                <Avatar
                    sx={{ width: 100, height: 100 }}
                    alt="profile"
                    src={imageSrc ? `${BASE_URL}/${VERSION}/${PROFILE_IMAGE_URL}/${imageSrc}` : ""}
                    onClick={handleEditClick}
                />
            </Badge>
        </Box>
    );
};

export default ViewProfilePicture;
