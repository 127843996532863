import React from "react";
import ChangeProfilePicture from "./change-profile-picture";
import ViewProfilePicture from "./view-profile-picture";

// Profile Picture omponent
const ProfilePicture = (props) => {
    const {
        page,
        edit,
        isOpen,
        setIsOpen,
        setFile,
        refetch,
        imageSrc
    } = props

    // EVENT HANDLER
    const toggle = (isOpened) => {
        setIsOpen(isOpened);
    };

    // RENDER
    return (
        <React.Fragment>
            <ViewProfilePicture
                viewEditIcon={edit}
                setToggle={toggle}
                imageSrc={imageSrc}
            />
            <ChangeProfilePicture
                page={page}
                setToggle={toggle}
                refetch={refetch}
                setFile={setFile}
                open={isOpen && edit}
                setOpen={setIsOpen}
            />
        </React.Fragment>
    );
};
export default ProfilePicture;