import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { Container } from "@mui/system";
import HasPermission from "../../utils/access";

// Roles Update Component
function RolesUpdate({
    handleModalClose,
    roleData,
    refetch,
    isNew
    /* 
      If isNew is true the form is used to add a new role, 
      if isNew is false the form is used to update an existing role.
    */
}) {
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        setValue
    } = useForm({
        mode: "onChange"
    });

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // QUERIES $ MUTATIONS
    const { data: permissions } = useQuery(["permissions"], () => {
        return axiosPrivate.get(`/roles/permissions`, {
            params: {
                page: -1
            },
        });
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const { mutate, isLoading, data, error } = useMutation((formData) => {
        return axiosPrivate.post(`/roles`, formData);
    });
    const {
        mutate: update,
        isLoading: updateIsLoading,
        data: updateData,
        error: updateError
    } = useMutation((formData) => {
        return axiosPrivate.put(`/roles/${roleData.name}`, formData);
    });

    // DATA STRUCTURE for Permissions Category By Name
    const permissionCategories = permissions?.data?.data?.map(element => element.category);
    const uniquePermissionCategories = [...new Set(permissionCategories)];

    // HELPERS
    const onSubmit = formData => {
        isNew ? (
            mutate(formData)
        ) : (
            update(formData)
        )
    }
    const viewChecked = (perm) => {
        return roleData.permissions.includes(perm) ? true : false;
    }

    // USEFFECTS
    useEffect(() => {
        if (!isNew && roleData) {
            for (var key in roleData) {
                if (roleData.hasOwnProperty(key)) {
                    setValue(key, roleData[key]);
                }
            }
        } else {
            setValue("permissions", [])
        }
    }, [isNew, setValue, roleData]);
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully created a new role.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            handleModalClose();
            refetch();
        }
        if (updateData) {
            enqueueSnackbar(`Succesfully updated.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            handleModalClose();
            refetch();
        }
    }, [data, enqueueSnackbar, handleModalClose, refetch, updateData]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    }
                );
            }
        }
        if (updateError) {
            if (updateError?.response?.data?.error?.field_error?.length > 0) {
                updateError?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                    });
                });
            } else {
                enqueueSnackbar(
                    updateError?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                    }
                );
            }
        }
    }, [enqueueSnackbar, error, updateError]);

    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    bgcolor: "primary.paper",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        m: 2,
                        maxHeight: "80vh"
                    }}
                >
                    {isNew ? (
                        <>
                            <Typography sx={{ fontSize: 26, fontWeight: 'bold' }}>
                                Add New Role
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>
                                Add new roles with permissions below
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography sx={{ fontSize: 26, fontWeight: 'bold' }}>
                                Update Roles
                            </Typography>
                            <Typography sx={{ fontSize: 20 }}>
                                Update roles with permissions below
                            </Typography>
                        </>
                    )}
                    <Divider />
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: "Name is required",
                            pattern: {
                                value: /^\S+$/,
                                message: "Role name should not have a whitespace."
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Name"
                                fullWidth
                                disabled={!isNew}
                                autoComplete="name"
                                error={!!errors?.name}
                                helperText={errors?.name ? errors.name.message : null}
                                size='small'
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                                {...field}
                            />
                        )}
                    />
                    <Divider />
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', mt: 1 }}>
                        Permissions
                    </Typography>
                    {!HasPermission("get_all_permissions") ? (
                        <Box sx={{ ml:2, my:1, color:"red" }}>
                            You don't have access for permissions list!
                        </Box>
                    ):(
                    <Box
                        sx={{
                            maxHeight: "70vh",
                            overflowY: "visible",
                            overflowX: "hidden",
                        }}
                    >
                        <FormControl>
                            {uniquePermissionCategories.map((category) => (
                                <FormGroup
                                    key={category === undefined ? "Uncategorised" : category}
                                    sx={{
                                        mt: 1
                                    }}
                                >
                                    <Typography sx={{ fontWeight: "medium" }}>
                                        {category === undefined ?
                                            "UNCATEGORISED" : category.toUpperCase()
                                        }
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                        }}
                                    >
                                        {permissions?.data?.data?.map((item, index) => (
                                            (item.category === category) &&
                                            <Box
                                                component="span"
                                                sx={{
                                                    visibility: 'visible',
                                                    my: 2,
                                                    mx: 1,
                                                    p: 0.7,
                                                    bgcolor: 'grey.100',
                                                    color: 'grey.800',
                                                    border: '1px solid',
                                                    borderColor: 'grey.300',
                                                    borderRadius: 2,
                                                    fontSize: 14,
                                                    fontWeight: '700',
                                                }}
                                            >
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.id}
                                                    control={
                                                        <Checkbox
                                                            defaultChecked={!isNew && viewChecked(item.id)}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#fbcf3b',
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={item.name}
                                                    name={`${item.id}`}
                                                    {...register("permissions")}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Divider />
                                </FormGroup>
                            ))}
                        </FormControl>
                    </Box>
                    )}
                    <Divider />
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                ":hover": { bgcolor: "#FFAA00" },
                                backgroundColor: "#1c2526",
                                color: "#fff",
                            }}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                bgcolor: "#fdbc3d",
                                ":hover": { bgcolor: '#1c2526' }
                            }}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            <Typography sx={{ color: "#fff" }}>
                                {isNew ?
                                    'Add' : 'Update'
                                }
                            </Typography>
                            {(isLoading || updateIsLoading) && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "secondary.main",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Grid>
    );
}
export default RolesUpdate;