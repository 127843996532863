import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

// Permission Access Validator
function HasPermission(permission) {
    const { permissions, role } = useContext(AuthContext);

    return (role !== "super-user" && role !== null) ?
        (
            permissions?.includes(permission)
        ) : (
            role === "super-user" || false
        );
}
export default HasPermission;