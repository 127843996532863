import { useContext, useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Dialog, DialogActions, DialogContent, Divider } from "@mui/material";
import { useQuery } from "react-query";
import logo from "../../utils/images/ridePlus.png";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
import { queryClient } from "../../App";
import RequestOtp from "./requestOtp";
import Consent from "../consent/consent";
import { useSnackbar } from "notistack";
import { phoneRegex } from "../../utils/validationPattern";

function SignInWithPhone({ consentId }) {
  const [openDialog, setOpenDialog] = useState(false);
  const userInput = useRef({});
  const [requestOtp, setRequestOtp] = useState(false)

  const navigate = useNavigate();

  // USEFORM HOOK
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    mode: "onSubmit",
  });

  userInput.current = watch("phone", "");

  const { auth, persist, redirectUri } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar()
  const {
    data: identityProviders
  } = useQuery(["registered_identity_providers"], () => {
    return axios.get(`/registeredIdentityProviders`, {
      params: {
        page: -1
      },
    });
  },
  );

  const {
    isFetching,
    error,
  } = useQuery(["login_with_phone_otp"], () =>
    axios.get(
      '/otp',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          type: "login",
          phone: userInput.current
        }
      }),
    {
      onSuccess: () => {
        setRequestOtp(false)
        setOpenDialog(true)
      },
      enabled: requestOtp,
    }
  );

  const onSubmit = () => {
    if (errors?.phone === undefined) {
      setRequestOtp(true)
    }
  }

  useEffect(() => {
    localStorage.setItem("persist", persist);
    queryClient.clear();
  }, [persist]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
          }
        );
      }
      setRequestOtp(false)
    }
    queryClient.clear();
  }, [enqueueSnackbar, error, setRequestOtp]);
  // RENDER
  return auth?.access_token ? (
    redirectUri ?
      <Consent consentId={consentId} /> : navigate("/")
  ) : (
    <>
      {openDialog ?
        <RequestOtp openDialog={openDialog} userInput={userInput.current} setOpenDialog={setOpenDialog} />
        :
        <Dialog
          open={!openDialog}
          sx={{
            "& .MuiDialog-paper": {
              boxShadow: "0px 1px 1px 1px white",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          PaperProps={{ sx: { width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" }, height: "auto" } }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent
            sx={{
              px: 0,
              pb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                mb: 2,
              }}
            >
              <Avatar
                sx={{ width: "140px", height: "100px", m: 1, borderRadius: 0 }}
                src={logo}
              />
            </Box>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Phone is required",
                pattern: {
                  value: phoneRegex,
                  message: "Invalid input",
                },
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  sx={{
                    mt: 1,
                    mb: 2,
                  }}
                  fullWidth
                  label="Phone No"
                  autoFocus
                  helperText={errors?.phone ? errors?.phone?.message : ""}
                  error={errors?.phone}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: "column",
              px: 0,
            }}
          >
            <Button
              fullWidth
              sx={{
                bgcolor: "secondary.main",
                ":hover": {
                  bgcolor: "secondary.main"
                },
                color: "#fff",
                mb: 1,
                fontWeight: "bold",
                borderRadius: 6
              }}
              type="submit"
              size="large"
            >
              Login
              {isFetching && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: { sm: "row", xs: "column" },
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography sx={{ textAlign: "center", fontWeight: "medium", pr: 1 }}>
                Don't have an account?
              </Typography>
              <Button
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: 16,
                }}
                onClick={() => navigate("/register")}
              >
                Create Account
              </Button>
            </Box>
            {identityProviders?.data?.data?.length > 1 &&
              <>
                <Divider> Or </Divider >
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {identityProviders?.data?.data?.map((IP) =>
                    <Button
                      key={IP?.id}
                      fullWidth
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.paper",
                        textTransform: "none",
                        mb: 1.5,
                        borderRadius: 6,
                      }}
                    >
                      <Avatar sx={{ width: 30, height: 30, mr: 1 }} src={IP.logo_uri} />
                      <Typography sx={{ textAlign: "center", mt: 0.5, fontSize: 14, fontWeight: "bold" }}>
                        Sign In With {IP?.name}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </>
            }
          </DialogActions>
        </Dialog>
      }
    </>
  );
}
export default SignInWithPhone;