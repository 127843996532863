import { Box } from "@mui/system";
import { useEffect } from "react";
import PinInput from "react-pin-input";

function CustomPinInput(props) {
  const { handleOnSubmit, setInputValue } = props;

  useEffect(() => {
    const cb = (e) => {
      setInputValue(e.clipboardData.getData("text"));
    };
    window.addEventListener("paste", cb);
    return () => {
      window.removeEventListener("paste", cb);
    };
  }, [setInputValue]);

  return (
    <>
      <Box
        sx={{
          display: { xs: "block", md: "none", lg: "none", xl: "none" },
        }}
      >
        <PinInput
          focus
          length={6}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: "2px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          inputStyle={{
            background: "#F5F5F5",
            border: "1px solid #878787",
            fontSize: "16px",
            width: "36px",
            height: "44px",
            borderRadius: "8px",
            margin: "2px 2px",
          }}
          inputFocusStyle={{ borderColor: "black" }}
          onChange={(value) => {
            setInputValue(value);
          }}
          onComplete={(value) => {
            handleOnSubmit({ otp: value });
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block", lg: "none", xl: "none" },
        }}
      >
        <PinInput
          focus
          length={6}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: "2px",
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
          }}
          inputStyle={{
            background: "#F5F5F5",
            border: "1px solid #878787",
            fontSize: "18px",
            width: "54px",
            height: "60px",
            borderRadius: "8px",
            margin: "2px 4px",
          }}
          inputFocusStyle={{ borderColor: "black" }}
          onChange={(value) => {
            setInputValue(value);
          }}
          onComplete={(value) => {
            handleOnSubmit({ otp: value });
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "none", lg: "block", xl: "none" },
        }}
      >
        <PinInput
          focus
          length={6}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          inputStyle={{
            background: "#F5F5F5",
            border: "1px solid #878787",
            fontSize: "18px",
            width: "48px",
            height: "58px",
            borderRadius: "8px",
            margin: "2px 3px",
          }}
          inputFocusStyle={{ borderColor: "black" }}
          onChange={(value) => {
            setInputValue(value);
          }}
          onComplete={(value) => {
            handleOnSubmit({ otp: value });
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "none", lg: "none", xl: "block" },
        }}
      >
        <PinInput
          focus
          length={6}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          inputStyle={{
            background: "#F5F5F5",
            border: "1px solid #878787",
            fontSize: "26px",
            width: "62px",
            height: "68px",
            borderRadius: "8px",
            margin: "2px 8px",
          }}
          inputFocusStyle={{ borderColor: "black" }}
          onChange={(value) => {
            setInputValue(value);
          }}
          onComplete={(value) => {
            handleOnSubmit({ otp: value });
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
    </>
  );
}
export default CustomPinInput;
