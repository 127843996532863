import { useState, useRef, useCallback, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal
} from "@mui/material";
import ScopesUpdate from './scopes-update';
import CustomToolbar from '../customToolbar';
import moment from 'moment';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import ScopeDetails from './scope-details';
import HasPermission from '../../utils/access';
import Error from '../error';

// Scopes List Component
function Scopes() {
  // USESTATES & USEREFS
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [searchFocus, setSearchFocus] = useState(true);
  const [filterFocus, setFilterFocus] = useState(true);
  const [columnField, setColumnField] = useState();
  const [operatorValue, setOperatorValue] = useState();
  const [values, setValue] = useState();
  const [sortModel, setSortModel] = useState([
    { field: "name", sort: "asc" },
  ]);

  const scopeName = useRef()
  let scopeData = useRef({})

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // QUERY & MUTATION   
  const filterOptions = { columnField, operatorValue, values }
  const {
    isLoading: scopesDataLoading,
    data: scopesData,
    refetch,
    error: fetchScopesError
  } = useQuery(["scopes", page, perPage, filterOptions, sortModel], () => {
    return axiosPrivate.get(`/oauth/scopes`, {
      params: {
        page: page,
        per_page: perPage,
        sort: JSON.stringify(sortModel),
        filter: (
          filterOptions.columnField === undefined ||
          filterOptions.operatorValue === undefined ||
          filterOptions.values === undefined
        ) ? [] : JSON.stringify([{
          column_field: filterOptions?.columnField,
          operator_value: filterOptions?.operatorValue,
          value: filterOptions?.values
        }])
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );
  const { mutate: deleteScope, isLoading, data, error } = useMutation((name) => {
    return axiosPrivate.delete(`/oauth/scopes/${name}`);
  });

  // EVENT HANDLERS & HELPERS
  const handleEdit = (scope) => {
    scopeName.current = scope.name;
    scopeData.current = scope;
    setUpdate(true);
    setOpen(true);
    return scopeData.current;
  }
  const handleView = (scope) => {
    scopeName.current = scope.name;
    setView(true);
    setOpen(true);
  }
  const handleViewModalClose = () => {
    setOpen(false);
    setView(false);
  }
  const handleModalClose = () => {
    setOpen(false);
  }
  const handleDelete = () => {
    deleteScope(scopeName.current);
  }
  const handleClick = (name) => {
    setOpenDialog(true)
    scopeName.current = name
  }
  const handleDrawer = () => {
    setOpen(false)
    setUpdate(false);
  }
  const handleOnFilterFocus = () => {
    setFilterFocus(true)
    setSearchFocus(false)
  }
  const handleOnSearchFocus = () => {
    setFilterFocus(false)
    setSearchFocus(true)
  }
  const onFilterChange = useCallback((filterModel) => {
    if (filterFocus && filterModel?.items.length !== 0) {
      filterModel.quickFilterValues = [];
      setColumnField(filterModel?.items[0]?.columnField);
      setOperatorValue(filterModel?.items[0]?.operatorValue);
      setValue(filterModel?.items[0]?.value);
    } else {
      filterModel.items = [];
      setColumnField("*");
      setOperatorValue("contains");
      setValue(JSON.stringify({
        columns: ["name", "description", "resource_server_name"],
        value: filterModel?.quickFilterValues[0]
      }));
    }
  }, [filterFocus]);
  const onSortChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  // USEFFECT
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully deleted.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      setOpenDialog(false);
      refetch();
    }
  }, [data, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
        error?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
    }
  }, [enqueueSnackbar, error]);
  // DATA STRUCTURE
  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.1 },
    {
      field: 'created_at', headerName: 'Created At', type: 'date', flex: 0.2,
      valueGetter: (params) =>
        moment(params?.row?.created_at).format("llll")
    },
    { field: 'description', headerName: 'Description', flex: 0.1 },
    { field: 'resource_server_name', headerName: 'Resource Server', flex: 0.1 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.3,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ flex: 1 }}>
            <Button
              sx={{ minWidth: 24, p: 1, color: 'black' }}
              onClick={() => handleView(params.row)}
              disabled={!HasPermission("get_scope")}
            >
              <Visibility />
            </Button>
            <Button
              sx={{ minWidth: 24, p: 1, color: 'black' }}
              onClick={() => handleEdit(params.row)}
              disabled={!HasPermission("update_scope")}
            >
              <Edit />
            </Button>
            <Button
              key={params.row.name}
              sx={{ minWidth: 24, p: 1, color: 'red' }}
              onClick={() => handleClick(params.row.name)}
              disabled={!HasPermission("delete_scope")}
            >
              <Delete />
            </Button>
            <div>
              <Dialog
                id="delete-scopes-by-id"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ invisible: true }}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm delete operation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want delete scope `{scopeName.current}`?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenDialog(false)}
                    sx={{ bgcolor: '#707070', color: 'black' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    sx={{ bgcolor: 'red', color: 'black' }}
                    disabled={isLoading}
                  >
                    Delete
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "secondary.main",
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>
        );
      },
    },
  ];

  // CUSTOM COMPONENT
  function DataGridCustomToolbar() {
    return (
      <CustomToolbar
        searchFocus={searchFocus}
        handleOpen={() => {
          setOpen(true);
          setSearchFocus(false);
          setUpdate(false);
        }}
        handleOnFilterFocus={handleOnFilterFocus}
        handleOnSearchFocus={handleOnSearchFocus}
        page="Scopes"
        permission="create_scope"
      />
    )
  }

  // RENDERING
  return fetchScopesError ? (
    <Error error={fetchScopesError?.response?.data?.error} />
  ) : (
    <Box
      sx={{
        backgroundColor: "#fff",
        flex: 5,
        p: 0
      }}
    >
      <Modal
        open={open}
        children={
          view ? (
            <ScopeDetails
              handleViewModalClose={handleViewModalClose}
              scopeName={scopeName.current}
            />
          ) : (
            <ScopesUpdate
              handleModalClose={handleModalClose}
              isNew={!update}
              scopeData={scopeData.current}
              refetch={refetch}
            />
          )}
        onClose={handleDrawer}
      />
      <DataGrid
        sx={{
          pt: 2,
          px: 2,
          fontSize: 12,
          overflow: 'hidden',
          resize: 'both'
        }}
        getRowId={(row) => row.name}
        rows={scopesData?.data?.data || []}
        columns={columns}
        loading={scopesDataLoading}
        autoHeight
        pagination
        page={page}
        pageSize={perPage}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowCount={scopesData?.data?.meta_data?.total}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        sortingMode="server"
        sortModel={sortModel}
        sortingOrder={["desc", "asc"]}
        onSortModelChange={onSortChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        components={{ Toolbar: DataGridCustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 1000 }
          }
        }}
      />
    </Box>
  )
}
export default Scopes;