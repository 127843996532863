import { Button, CircularProgress, Container, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Users Update Component
const UsersUpdate = ({ handleModalClose, refetch }) => {
    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // HOOK FORM
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    // MUTATION & QUERY
    const { mutate, data, error, isLoading } = useMutation((formData) => {
        const dataSent= {...formData, role: "test"}
        return axiosPrivate.post(`/users`, dataSent);
    });
    const { data: roles } = useQuery(["get_all_roles"], () => {
        return axiosPrivate.get(`/roles`, {
            params: {
                page: -1
            },
        });
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );

    // USEFFECTS
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully added user!`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            refetch()
            reset();
            handleModalClose();;
        }
    }, [data, enqueueSnackbar, handleModalClose, refetch, reset]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    }
                );
            }
        }
    }, [enqueueSnackbar, error]);

    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    bgcolor: "primary.paper",
                    borderRadius: 4
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        my: 3,
                        mx: 2
                    }}
                >
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
                            Add New User
                        </Typography>
                    </Box>
                    <Divider sx={{ mt: 0.5 }} />
                    <Controller
                        name="first_name"
                        control={control}
                        rules={{
                            required: "First name is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                size="small"
                                label="First name"
                                helperText={
                                    errors?.first_name ? errors?.first_name?.message : ""
                                }
                                error={errors?.first_name}
                                sx={{
                                    mt: 2,
                                    mb: 0.5
                                }}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="middle_name"
                        control={control}
                        rules={{
                            required: "Middle name is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                size="small"
                                label="Middle name"
                                helperText={
                                    errors?.middle_name ? errors?.middle_name?.message : ""
                                }
                                error={errors?.middle_name}
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="last_name"
                        control={control}
                        rules={{
                            required: "Last name is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                size="small"
                                label="Last name"
                                helperText={
                                    errors?.last_name ? errors?.last_name?.message : ""
                                }
                                error={errors?.last_name}
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Gender"
                                margin="normal"
                                fullWidth
                                select
                                size='small'
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                                autoComplete="gender"
                                autoFocus
                                error={!!errors?.gender}
                                helperText={errors?.gender ? errors.gender.message : null}
                                {...field}
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </TextField>
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "Email is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                helperText={errors?.email ? errors?.email?.message : ""}
                                error={errors?.email}
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: "Phone number is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                type="number"
                                fullWidth
                                size="small"
                                label="Phone Number"
                                helperText={errors?.phone ? errors?.phone?.message : ""}
                                error={errors?.phone}
                                {...field}
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="role"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Role"
                                margin="normal"
                                fullWidth
                                select
                                size='small'
                                sx={{
                                    mt: 1.5,
                                    mb: 2,
                                    display: "none" /* Feature Toggle. */
                                }}
                                autoComplete="role"
                                autoFocus
                                {...field}
                            >
                                <MenuItem value="">
                                    {"None"}
                                </MenuItem>
                                {roles?.data?.data?.map((role) =>
                                    <MenuItem key={role.name} value={role.name}>
                                        {role.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        )}
                    />
                    <Divider />
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            sx={{
                                ":hover": { bgcolor: "#FFAA00" },
                                backgroundColor: "#1c2526",
                                color: "#fff",
                            }}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            onClick={handleSubmit((data) => mutate(data))}
                            variant="contained"
                            disabled={isLoading}
                        >
                            Submit
                            {isLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "secondary.main",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                    </Box>
                </Box>
            </Container >
        </Grid >
    );
};
export default UsersUpdate;