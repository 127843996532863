import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

// Identity Providers Update Component
function IdentityProvidersUpdate({ 
  handleModalClose, 
  isNew, 
  identityProviderData, 
  refetch 
}) {
  // HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: isNew ? {} : identityProviderData
  });

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // MUTATION
  const { mutate, isLoading, data, error } = useMutation((formData) => {
    return axiosPrivate.post(`/identityProviders`, formData);
  });

  const {
    mutate: update,
    isLoading: updateIsLoading,
    data: updateData,
    error: updateError
  } = useMutation((formData) => {
    return axiosPrivate.put(`/identityProviders/${identityProviderData.id}`, formData);
  });

  // HELPERS
  const onSubmit = formData => {
    isNew ? (
      mutate(formData)
    ) : (
      update(formData)
    )
  }

  // USEEFFECT 
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully created a new identity provider.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      handleModalClose();
      refetch();
    }
    if (updateData) {
      enqueueSnackbar(`Succesfully updated.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      handleModalClose();
      refetch();
    }
  }, [data, enqueueSnackbar, handleModalClose, refetch, updateData]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      }
    }
    if (updateError) {
      if (updateError?.response?.data?.error?.field_error?.length > 0) {
        updateError?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          updateError?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      }
    }
  }, [enqueueSnackbar, error, updateError]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          bgcolor: "primary.paper",
          borderRadius: 4
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            my: 2
          }}
        >
          {isNew ? (
            <>
              <Typography sx={{ fontSize: 26, fontWeight: 'bold', textAlign: "center" }}> 
                Add New Identity Provider
              </Typography>
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 26, fontWeight: 'bold', textAlign: "center" }}> 
                Update Identity Provider Data
              </Typography>
            </>
          )}
          <Divider />
          <Box
            sx={{
              maxHeight: "80vh",
              overflowY: "visible",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                mx: 1,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Name"
                    fullWidth
                    autoComplete="name"
                    multiline
                    error={!!errors?.name}
                    helperText={errors?.name ? errors.name.message : null}
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="logo_uri"
                control={control}
                rules={{
                  required: "Logo URI is required.",
                  pattern: {
                    value: /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Logo URI"
                    margin="normal"
                    fullWidth
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    autoComplete="logo_uri"
                    multiline
                    autoFocus
                    error={!!errors?.logo_uri}
                    helperText={errors?.logo_uri ? errors.logo_uri.message : null}
                    {...field}
                  />
                )}
              />
              <Controller
                name="client_id"
                control={control}
                rules={{
                  required: "Client ID is required",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Client ID"
                    fullWidth
                    autoComplete="client_id"
                    multiline
                    error={!!errors?.client_id}
                    helperText={errors?.client_id ? errors.client_id.message : null}
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="client_secret"
                control={control}
                rules={{
                  required: "Client Secret is required",
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Client Secret"
                    fullWidth
                    autoComplete="client_secret"
                    multiline
                    error={!!errors?.client_secret}
                    helperText={errors?.client_secret ? errors.client_secret.message : null}
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="redirect_uri"
                control={control}
                rules={{
                  required: "Redirect URI is required.",
                  pattern: {
                    value: /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Redirect URI"
                    margin="normal"
                    fullWidth
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    autoComplete="redirect_uri"
                    multiline
                    autoFocus
                    error={!!errors?.redirect_uri}
                    helperText={errors?.redirect_uri ? errors.redirect_uri.message : null}
                    {...field}
                  />
                )}
              />
              <Controller
                name="authorization_uri"
                control={control}
                rules={{
                  required: "Authorization URI is required.",
                  pattern: {
                    value: /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Authorization URI"
                    margin="normal"
                    fullWidth
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    autoComplete="authorization_uri"
                    multiline
                    autoFocus
                    error={!!errors?.authorization_uri}
                    helperText={errors?.authorization_uri ? errors.authorization_uri.message : null}
                    {...field}
                  />
                )}
              />
              <Controller
                name="token_endpoint_uri"
                control={control}
                rules={{
                  required: "Token Endpoint URI is required.",
                  pattern: {
                    value: /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Token Endpoint URI"
                    margin="normal"
                    fullWidth
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 0.5
                    }}
                    autoComplete="token_endpoint_uri"
                    multiline
                    autoFocus
                    error={!!errors?.token_endpoint_uri}
                    helperText={errors?.token_endpoint_uri ? errors.token_endpoint_uri.message : null}
                    {...field}
                  />
                )}
              />
              <Controller
                name="user_info_endpoint_uri"
                control={control}
                rules={{
                  required: "User Info Endpoint URI is required.",
                  pattern: {
                    value: /^((http|https):\/\/)?([A-z.-]+)\.([A-z]+)\.([A-z]{2,})/,
                    message: "Invalid url",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="User Info Endpoint URI"
                    margin="normal"
                    fullWidth
                    size='small'
                    sx={{
                      mt: 1.5,
                      mb: 1.5
                    }}
                    autoComplete="user_info_endpoint_uri"
                    multiline
                    autoFocus
                    error={!!errors?.user_info_endpoint_uri}
                    helperText={errors?.user_info_endpoint_uri ? errors.user_info_endpoint_uri.message : null}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                ":hover": { bgcolor: "#FFAA00" },
                backgroundColor: "#1c2526",
                color: "#fff",
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                bgcolor: "#fdbc3d",
                ":hover": { bgcolor: '#1c2526' }
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <Typography sx={{ color: "#fff" }}>
                {isNew ?
                  'Add' : 'Update'
                }
              </Typography>
              {(isLoading || updateIsLoading) && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "secondary.main",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default IdentityProvidersUpdate;