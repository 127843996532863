import { Controller, useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../utils/images/ridePlus.png";
import AuthContext from "../../context/AuthProvider";
import ProfilePicture from "../profile/profile-picture";
import SignInWithPhone from "../signIn/signInWithPhone";
import CustomPinInput from "../../components/customPinInput";
import { phoneRegex } from "../../utils/validationPattern";

function Register({ consentId }) {

  // USESTATE & USEREF
  const [openDrawer, setOpenDrawer] = useState(false);
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmInputType, setConfirmInputType] = useState("password");
  const [file, setFile] = useState("");
  const [open, setOpen] = useState(false);
  const [requestOtp, setRequestOtp] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const password = useRef({});
  const userData = useRef({});

  const { redirectUri } = useContext(AuthContext)

  // NAVIGATE & SNACKBAR
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // REACT HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    mode: "onSubmit"
  });

  password.current = watch("password", "");

  // MUTATION & USEQUERY
  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios.post(
      '/register',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  });
  const { isFetching: otpIsFetching, data: otpData, error: otpError } = useQuery(["otp_on_registration"], () => axios.get(
    '/otp',
    {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        type: "signup",
        phone: userData.current.phone
      }
    }),
    {
      enabled: requestOtp,
    }
  );

  // EVENT HANDLERS & HELPERS
  const handleClick = () => {
    if (redirectUri) {
      navigate(redirectUri, { replace: true })
    } else {
      navigate("/login")
    }
  };
  const handleRegister = (form) => {
    setRequestOtp(true)
    setOpenDrawer(true);
    setPhone(form?.phone);
    userData.current = form;
  };
  const handleSignup = (form) => {
    userData.current = { ...userData.current, otp: form.otp };
    const dataToBeSent = file !== "" ? { ...userData.current, profile_picture: file } : userData.current;
    mutate(dataToBeSent);
  };

  // USEEFFECT 
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`You're succesfully registered!`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      setOpenDrawer(false);
      setRequestOtp(false)
    }
  }, [consentId, data, enqueueSnackbar, redirectUri]);

  useEffect(() => {
    if (otpData && userData.current.phone !== undefined) {
      enqueueSnackbar(`Code sent to your phone!`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      setRequestOtp(false)
    }
  }, [enqueueSnackbar, otpData]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
        error?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
    }
    if (otpError && userData.current.phone !== undefined) {
      enqueueSnackbar(
        otpError?.response?.data?.error?.message ||
        otpError?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
      setRequestOtp(false)
    }
  }, [enqueueSnackbar, error, otpError]);

  //RENDER
  return data ? (
    <SignInWithPhone consentId={consentId} />
  ) : (
    <>
      {(openDrawer && !otpIsFetching && !otpError) ? (
        <Dialog
          open={openDrawer}
          sx={{
            "& .MuiDialog-paper": {
              boxShadow: "0px 1px 1px 1px white",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: 'white'
            }
          }}
          PaperProps={{ sx: { width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" }, height: "auto" } }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Avatar
              sx={{ width: "140px", height: "100px", m: 1, borderRadius: 0 }}
              src={logo}
            />
          </DialogTitle>
          <DialogContent
            sx={{
              px: 0
            }}
          >
            <Typography sx={{ fontSize: 16, pt: 1, mb: 2, }}>
              Please fill the code sent to your phone number: {phone} via SMS.
            </Typography>
            <CustomPinInput handleOnSubmit={handleSignup} setInputValue={setInputValue} />
          </DialogContent>
          <DialogActions
            sx={{
              px: 0
            }}
          >
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ":hover": { bgcolor: "#e4e4e4" },
                  backgroundColor: "#e4e4e4",
                  color: "#1c2526",
                  fontWeight: "bold",
                  borderRadius: 6,
                  px: 1,
                  mr: 1.5,
                }}
                onClick={() => setOpenDrawer(false)}
              >
                Back
              </Button>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{
                  ":hover": { bgcolor: "secondary.main" },
                  backgroundColor: "secondary.main",
                  "&:disabled": {
                    backgroundColor: "secondary.main",
                    color: "#fff",
                  },
                  color: "#fff",
                  fontWeight: "bold",
                  borderRadius: 6,
                  px: 1,
                  ml: 1.5,
                }}
                onClick={() => handleSignup({ otp: inputValue })}
                disabled={isLoading || inputValue?.length !== 6}
                size="large"
              >
                Continue
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={!openDrawer}
          sx={{
            "& .MuiDialog-paper": {
              boxShadow: "0px 1px 1px 1px white",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          PaperProps={{ sx: { width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" }, height: "auto" } }}
          component="form"
          onSubmit={handleSubmit(handleRegister)}
        >
          <DialogContent
            sx={{
              px: 0
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: { xs: 0, md: 2 },
              }}
            >
              <ProfilePicture
                edit={true}
                isOpen={open}
                setIsOpen={setOpen}
                imageSrc={file}
                setFile={setFile}
              />
            </Box>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: "First Name is required",
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 1.5,
                    mb: 1
                  }}
                  label="First Name"
                  fullWidth
                  autoComplete="first_name"
                  defaultValue={userData.current?.first_name}
                  error={!!errors?.first_name}
                  helperText={
                    errors?.first_name ? errors.first_name.message : null
                  }
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              name="middle_name"
              control={control}
              rules={{
                required: "Middle Name is required",
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  variant="outlined"
                  label="Middle Name"
                  fullWidth
                  autoComplete="middle_name"
                  defaultValue={userData.current?.middle_name}
                  error={!!errors?.middle_name}
                  helperText={
                    errors?.middle_name ? errors.middle_name.message : null
                  }
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: "Last Name is required",
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  variant="outlined"
                  label="Last Name"
                  fullWidth
                  autoComplete="last_name"
                  defaultValue={userData.current?.last_name}
                  error={!!errors?.last_name}
                  helperText={errors?.last_name ? errors.last_name.message : null}
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  variant="outlined"
                  label="Gender"
                  fullWidth
                  select
                  size="small"
                  autoComplete="gender"
                  defaultValue={userData.current?.gender}
                  error={!!errors?.gender}
                  helperText={errors?.gender ? errors.gender.message : null}
                  {...field}
                >
                  <MenuItem value="Male">
                    Male
                  </MenuItem>
                  <MenuItem value="Female">
                    Female
                  </MenuItem>
                </TextField>
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Phone is required",
                pattern: {
                  value: phoneRegex,
                  message: "Invalid input",
                },
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  autoComplete="phone"
                  defaultValue={userData.current?.phone}
                  error={!!errors?.phone}
                  helperText={errors?.phone ? errors.phone.message : null}
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  variant="outlined"
                  label="Email"
                  fullWidth
                  autoComplete="email"
                  defaultValue={userData.current?.email}
                  error={!!errors?.email}
                  helperText={errors?.email ? errors.email.message : null}
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "At least 6 characters is required."
                },
                maxLength: {
                  value: 32,
                  message: "The maximum characters allowed is 32."
                }
              }}
              render={({ field }) => (
                <TextField
                  id="password-input"
                  sx={{
                    mt: 0.5,
                    mb: 1
                  }}
                  label="Password"
                  fullWidth
                  type={inputType}
                  autoComplete="current-password"
                  defaultValue={userData.current?.password}
                  error={!!errors?.password}
                  helperText={errors?.password ? errors.password.message : null}
                  size="small"
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setShowPassword(showPassword === false ? true : false);
                            setInputType(showPassword === false ? "text" : "password");
                          }}
                        >
                          {showPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirm_password"
              control={control}
              rules={{
                validate: value =>
                  value === password.current || "The passwords do not match"
              }}
              render={({ field }) => (
                <TextField
                  id="confirm-password-input"
                  sx={{
                    mt: 0.5,
                    mb: 1.5
                  }}
                  label="Confirm Password"
                  fullWidth
                  type={confirmInputType}
                  defaultValue={userData.current?.confirm_password}
                  autoComplete="confirm-current-password"
                  error={!!errors?.confirm_password}
                  helperText={
                    errors?.confirm_password
                      ? errors.confirm_password.message
                      : null
                  }
                  size="small"
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setShowConfirmPassword(showConfirmPassword === false ? true : false);
                            setConfirmInputType(showConfirmPassword === false ? "text" : "password");
                          }}
                        >
                          {showConfirmPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            sx={{
              px: 0
            }}
          >
            <Box
              sx={{
                mt: 1.5,
                display: { md: "flex", xs: "none" },
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  display: {
                    md: "block",
                    xs: "none"
                  },
                  bgcolor: "#1c2526",
                  ":hover": {
                    bgcolor: "#1c2526"
                  }
                }}
                onClick={() => handleClick()}
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>Back</Typography>
              </Button>
              <Button
                alignItems
                size="small"
                sx={{
                  display: {
                    md: "block",
                    xs: "none"
                  },
                  bgcolor: "#fdbc3d",
                  ":hover": {
                    bgcolor: "#1c2526"
                  }
                }}
                type="submit"
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>
                  Register
                  {otpIsFetching && userData.current?.phone !== undefined && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "secondary.main",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Typography>
              </Button>
              <Button
                size="large"
                variant="contained"
                fullWidth
                sx={{
                  display: {
                    md: "none",
                    xs: "block"
                  },
                  ":hover": { bgcolor: "#e4e4e4" },
                  backgroundColor: "#e4e4e4",
                  color: "#1c2526",
                  mr: 3,
                  borderRadius: 6,
                  fontWeight: "bold",
                }}
                onClick={() => handleClick()}
              >
                <Typography sx={{ fontSize: 14 }}>Back</Typography>
              </Button>
              <Button
                size="large"
                fullWidth
                sx={{
                  display: {
                    md: "none",
                    xs: "block"
                  },
                  bgcolor: "#1c2526",
                  ":hover": {
                    bgcolor: "#1c2526"
                  },
                  borderRadius: 6,
                  fontWeight: "bold",
                }}
                type="submit"
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>
                  Register
                  {otpIsFetching && userData.current?.phone !== undefined && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "secondary.main",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                mt: 1.5,
                display: { xs: "flex", md: "none" },
                flexDirection: "column",
                width: "100%"
              }}
            >
              <Button
                size="large"
                fullWidth
                sx={{
                  bgcolor: { md: "#fdbc3d", xs: "#1c2526" },
                  ":hover": { bgcolor: { md: "#fdbc3d", xs: "#1c2526" } },
                  borderRadius: 6,
                  fontWeight: "bold",
                }}
                type="submit"
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>
                  Register
                  {otpIsFetching && userData.current?.phone !== undefined && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#fff",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Typography>
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export default Register;