import { useState, useRef, useCallback, useEffect } from 'react';
import { useMutation, useQuery } from "react-query";
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Switch } from "@mui/material";
import RolesUpdate from "./roles-update";
import CustomToolbar from '../customToolbar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import RolesDetail from './roles-detail';
import HasPermission from '../../utils/access';
import Error from '../error';

// Roles List View Component
function Roles() {
    // USESTATES & USEREFS
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [update, setUpdate] = useState(false);
    const [view, setView] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [searchFocus, setSearchFocus] = useState(true);
    const [filterFocus, setFilterFocus] = useState(true);
    const [columnField, setColumnField] = useState();
    const [operatorValue, setOperatorValue] = useState();
    const [values, setValue] = useState();
    const [sortModel, setSortModel] = useState([
        { field: "name", sort: "asc" },
    ]);

    const roleName = useRef();
    let roleData = useRef({});

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // QUERY & MUTATION   
    const filterOptions = { columnField, operatorValue, values }
    const {
        isLoading: rolesDataLoading,
        data: rolesData,
        refetch,
        error: fetchRolesError
    } = useQuery(["roles", page, perPage, filterOptions, sortModel], () => {
        return axiosPrivate.get(`/roles`, {
            params: {
                page: page,
                per_page: perPage,
                sort: JSON.stringify(sortModel),
                filter: (
                    filterOptions.columnField === undefined ||
                    filterOptions.operatorValue === undefined ||
                    filterOptions.values === undefined
                ) ? [] : JSON.stringify([{
                    column_field: filterOptions?.columnField,
                    operator_value: filterOptions?.operatorValue,
                    value: filterOptions?.values
                }])
            },
        });
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const { mutate, data, error } = useMutation((role) => {
        return axiosPrivate.patch(`/roles/${role.name}/status`, { status: role?.status });
    });
    const { mutate: deleteRole, isLoading: deleteIsLoading, data: deleteData, error: deleteError } = useMutation((name) => {
        return axiosPrivate.delete(`/roles/${name}`);
    });

    // EVENT HANDLERS & HELPERS
    const handleEdit = (role) => {
        roleData.current = role;
        setUpdate(true);
        setOpen(true);
        return roleData.current;
    }
    const handleView = (role) => {
        roleName.current = role.name;
        setView(true);
        setOpen(true);
    }
    const handleDelete = () => {
        deleteRole(roleName.current);
    }
    const handleClick = (role) => {
        setOpenDialog(true);
        roleName.current = role.name;
    }
    const handleSwitch = (role) => {
        roleName.current = role.name;
        roleData.current = {
            ...roleData.current,
            name: role.name,
            status: role.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        }
        mutate(roleData.current);
    }
    const handleDrawer = () => {
        setOpen(false);
        setUpdate(false);
        setView(false);
    }
    const handleViewModalClose = () => {
        setOpen(false);
        setView(false);
    }
    const handleModalClose = () => {
        setOpen(false);
        setUpdate(false);
        setView(false);
    }
    const handleOnFilterFocus = () => {
        setFilterFocus(true);
        setSearchFocus(false);
    }
    const handleOnSearchFocus = () => {
        setFilterFocus(false);
        setSearchFocus(true);
    }
    const onFilterChange = useCallback((filterModel) => {
        if (filterFocus && filterModel?.items.length !== 0) {
            filterModel.quickFilterValues = [];
            setColumnField(filterModel?.items[0]?.columnField);
            setOperatorValue(filterModel?.items[0]?.operatorValue);
            setValue(filterModel?.items[0]?.value);
        } else {
            filterModel.items = [];
            setColumnField("*");
            setOperatorValue("contains");
            setValue(JSON.stringify({
                columns: ["name", "status"],
                value: filterModel?.quickFilterValues[0]
            }));
        }
    }, [filterFocus]);
    const onSortChange = useCallback((newSortModel) => {
        setSortModel(newSortModel);
    }, []);

    // USEEFFECT
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully changed status.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOpenDialog(false);
            refetch();
        }
    }, [data, enqueueSnackbar, refetch]);
    useEffect(() => {
        if (deleteData) {
            enqueueSnackbar(`Succesfully deleted.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOpenDialog(false);
            refetch();
        }
    }, [deleteData, enqueueSnackbar, refetch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [error, enqueueSnackbar]);
    useEffect(() => {
        if (deleteError) {
            enqueueSnackbar(
                deleteError?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [deleteError, enqueueSnackbar, error]);

    // DATA STRUCTURE
    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'created_at', headerName: 'Created At', type: 'date', flex: 2,
            valueGetter: (params) =>
                moment(params?.row?.created_at).format("llll")
        },
        {
            field: 'updated_at', headerName: 'Updated At', type: 'date', flex: 2,
            valueGetter: (params) =>
                moment(params?.row?.created_at).format("llll")
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        sx={{
                            m: 2,
                            p: 2,
                            bgcolor: (
                                (params.row.status === 'ACTIVE' && params.row.name === "super-user"
                                ) || params.row.status !== 'ACTIVE'
                            ) ? '#fde2e2' : '#e5faea',
                            color: (
                                (params.row.status === 'ACTIVE' && params.row.name === "super-user"
                                ) || params.row.status !== 'ACTIVE'
                            ) ? '#d73936' : '#56d154',
                            fontSize: 10
                        }}
                        id={`${params.row.name}`}
                        onClick={() => handleSwitch(params.row)}
                        disabled={!HasPermission("change_role_status") || params.row.name === "super-user"}
                    >
                        {params.row.status}
                        <Switch
                            checked={params.row.status === 'ACTIVE' ? true : false}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: params.row.name === "super-user" ? '#fde2e2' : '#56d154'
                                }
                            }}
                            size="small"
                        />
                    </Button>
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Box sx={{ flex: 1 }}>
                        <Button
                            sx={{ minWidth: 24, p: 1, color: 'black' }}
                            onClick={() => handleView(params.row)}
                            disabled={!HasPermission("get_role")}
                        >
                            <Visibility />
                        </Button>
                        <Button
                            sx={{ minWidth: 24, p: 0, color: 'black' }}
                            onClick={() => handleEdit(params.row)}
                            disabled={!HasPermission("update_role") || params.row.name === "super-user"}
                        >
                            <Edit />
                        </Button>
                        <Button
                            sx={{ color: 'red' }}
                            onClick={() => handleClick(params.row)}
                            disabled={!HasPermission("delete_role") || params.row.name === "super-user"}
                        >
                            <Delete />
                        </Button>
                        <div>
                            <Dialog
                                id="delete-roles-by-id"
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                BackdropProps={{ invisible: true }}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Confirm delete operation"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want delete role `{roleName.current}`?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => setOpenDialog(false)}
                                        sx={{ bgcolor: '#707070', color: 'black' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => handleDelete()}
                                        sx={{ bgcolor: 'red', color: 'black' }}
                                        disabled={deleteIsLoading}
                                    >
                                        Delete
                                        {deleteIsLoading && (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: "secondary.main",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Box>
                );
            },
        },
    ];

    // CUSTOM COMPONENT
    function DataGridCustomToolbar() {
        return (
            <CustomToolbar
                searchFocus={searchFocus}
                handleOpen={() => {
                    setOpen(true);
                    setSearchFocus(false);
                    setUpdate(false);
                }}
                handleOnFilterFocus={handleOnFilterFocus}
                handleOnSearchFocus={handleOnSearchFocus}
                page="Role"
                permission="create_role"
            />
        )
    }

    // RENDERING
    return fetchRolesError ? (
        <Error error={fetchRolesError?.response?.data?.error} />
    ) : (
        <Box
            sx={{
                backgroundColor: "primary.paper",
                flex: 5,
                p: 0
            }}>
            <Modal
                open={open}
                children={
                    view ? (
                        <RolesDetail
                            handleViewModalClose={handleViewModalClose}
                            roleName={roleName.current}
                        />
                    ) : (
                        <RolesUpdate
                            handleModalClose={handleModalClose}
                            isNew={!update}
                            roleData={roleData.current}
                            refetch={refetch}
                        />
                    )}
                onClose={handleDrawer}
            />
            <DataGrid
                sx={{
                    pt: 2,
                    px: 2,
                    fontSize: 12,
                    overflow: 'hidden',
                    resize: 'both',
                    m: 2
                }}
                getRowId={(row) => row.name}
                rows={rolesData?.data?.data || []}
                columns={columns}
                loading={rolesDataLoading}
                autoHeight
                pagination
                page={page}
                pageSize={perPage}
                paginationMode="server"
                rowsPerPageOptions={[5, 10, 25, 100]}
                rowCount={rolesData?.data?.meta_data?.total}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
                sortingMode="server"
                sortModel={sortModel}
                sortingOrder={["desc", "asc"]}
                onSortModelChange={onSortChange}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 1000 }
                    }
                }}
            />
        </Box>
    )
}
export default Roles;