import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function DeleteMyAccount(props) {
  const { params, openDeleteAccount, setOpenDeleteAccount } = props;

  const axiosPrivate = useAxiosPrivate();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading, data, error } = useMutation(() => {
    return axiosPrivate.delete(`/profile`);
  });

  useEffect(() => {
    if (data?.status === 204) {
      enqueueSnackbar(`Succesfully deleted your account.`, {
        variant: "success",
      });
      window.location.href = `${params?.redirect_uri}?delete=delete`;
    }
  }, [data?.status, enqueueSnackbar, params?.redirect_uri]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, error]);

  return (
    <Dialog open={openDeleteAccount} maxWidth="xs">
      <DialogTitle
        id="delete-account-dialog-title"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#fafafa",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Confirm Delete Account Operation
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ ml: 1, mt: 2 }}>
          <Typography>
            Are you sure you want delete your Ride Plus account?
          </Typography>
          <Typography>This is an irriversible operation!</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mx: 2,
            pb: 1,
          }}
        >
          <Button
            onClick={() => setOpenDeleteAccount(false)}
            sx={{
              bgcolor: "black",
              ":hover": { bgcolor: "black" },
              color: "#fff",
              textTransform: "none",
              borderRadius: 6,
              fontWeight: "bold",
              mb: { xs: 1, md: 0 },
              mr: { xs: 0, md: 1 },
            }}
            size="large"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            sx={{
              bgcolor: "red",
              ":hover": {
                bgcolor: "red",
              },
              color: "#fff",
              textTransform: "none",
              borderRadius: 6,
              fontWeight: "bold",
            }}
            onClick={() => mutate()}
            size="large"
            fullWidth
          >
            Confirm
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default DeleteMyAccount;
