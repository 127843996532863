import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { UAParser } from 'ua-parser-js';
import { useMutation, useQuery } from 'react-query';
import { CheckCircle, Delete } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

// Active Sessions Component
function ActiveSessions() {
    const { auth } = useAuth();

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // MUTATION & QUERY
    const {
        isLoading,
        data,
        refetch
    } = useQuery(["active_sessions"], () => {
        return axiosPrivate.get(`profile/devices`);
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const {
        mutate,
        data: removeData,
        isLoading: removeIsLoading,
        error
    } = useMutation((refreshToken) => {
        return axiosPrivate.post(
            "/logout",
            {
                refresh_token: refreshToken
            },
            {
                withCredentials: true
            }
        );
    });

    if (data?.data?.data && auth) {
        const index = data?.data?.data?.findIndex((b) => auth?.refresh_token === b?.refresh_token);
        if (index !== -1)
            [data.data.data[0], data.data.data[index]] = [data.data.data[index], data.data.data[0]];
    }

    // USEEFFECT 
    useEffect(() => {
        if (removeData) {
            enqueueSnackbar(`Succesfully removed session.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            refetch();
        }
    }, [refetch, removeData, enqueueSnackbar]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);

    // RENDERING
    return (
        <CustomTable
            loading={isLoading}
            activeSessions={data?.data?.data}
            remove={mutate}
            removeIsLoading={removeIsLoading}
        />
    )
}
export default ActiveSessions;

// Logged-In Sessions Table Row
function LoggedInSession(props) {
    const [open, setOpen] = useState(false);
    const { row } = props;
    const { auth } = useAuth();
    let parser = new UAParser(row?.user_agent); /* User agent parser */
    let parserResults = parser.getResult();

    return props.loading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "100vh"
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    ) : (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell component="th" scope="row">
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            sx={{ mx: 0, px: 0 }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Typography sx={{ fontWeight: "bold", fontSize: 14, ml: 2, textAlign: "center" }}>
                            {parserResults.browser.name + " on " + parserResults.os.name}
                        </Typography>
                        {auth?.refresh_token === row.refresh_token &&
                            <CheckCircle sx={{ size: "small", color: "green", mx: 1, p: 0.3 }} />
                        }
                    </Box>
                </TableCell>
                <TableCell>
                    {open && auth?.refresh_token !== row.refresh_token &&
                        <Grid container justifyContent="flex-end">
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        display: { xs: "none", md: "block" },
                                        mx: 1,
                                        bgcolor: "primary.remove",
                                        color: "#fff",
                                        textTransform: "none"
                                    }}
                                    onClick={() => props.remove(row.refresh_token)}
                                >
                                    Remove Access
                                </Button>
                                <IconButton
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                        mx: 1,
                                        color: "#fff",
                                        textTransform: "none",
                                        borderRadius: 0
                                    }}
                                    onClick={() => props.remove(row.refresh_token)}
                                >
                                    <Delete size="small" sx={{ color: "primary.remove" }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 1, display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    flex: 0.5,
                                    mr: { xs: 0.5, md: 0 }
                                }}
                                gutterBottom
                                component="div"
                            >
                                Device:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <div>
                                    <Typography multiple sx={{ fontWeight: "bold", fontSize: 14 }}>
                                        Browser
                                    </Typography>
                                    <Typography multiple sx={{ fontSize: 12, mb: 1 }}>
                                        {parserResults.browser.name + ` (v${parserResults.browser.version})`}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography multiple sx={{ fontWeight: "bold", fontSize: 14 }}>
                                        OS
                                    </Typography>
                                    <Typography multiple sx={{ fontSize: 12, mb: 1 }}>
                                        {parserResults.os.name}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                        <Box sx={{ m: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    flex: 0.5,
                                    mr: { xs: 0.5, md: 0 }
                                }}
                                gutterBottom
                                component="div"
                            >
                                First Log-In:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 14 }}>
                                    {moment(row.created_at).format("llll")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ m: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    flex: 0.5,
                                    mr: { xs: 0.5, md: 0 }
                                }}
                                gutterBottom
                                component="div"
                            >
                                Recent Activity:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 14 }}>
                                    {moment(row.updated_at).format("llll")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ margin: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    flex: 0.5,
                                    mr: { xs: 0.5, md: 0 }
                                }}
                                gutterBottom
                                component="div"
                            >
                                Expires at:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 14 }}>
                                    {moment(row.expires_at).format("llll")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ m: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    flex: 0.5,
                                    mr: { xs: 0.5, md: 0 }
                                }}
                                gutterBottom
                                component="div"
                            >
                                IP Address:
                            </Typography>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography multiple sx={{ fontSize: 14 }}>
                                    {row.ip_address}
                                </Typography>
                            </Box>
                        </Box>
                        {auth?.refresh_token === row?.refresh_token &&
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    m: 1
                                }}
                            >
                                <CheckCircle sx={{ size: "small", color: "green", mr: { xs: 0, md: 1 }, p: 0.3 }} />
                                <Typography sx={{ textAlign: "center", fontSize: 14 }}>
                                    Your current session
                                </Typography>
                            </Box>}

                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// CUSTOM TABLE COMPONENT 
function CustomTable(props) {
    return (
        <Box
            sx={{
                display: "flex",
                overflow: "hidden",
                my: 1
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    overflowX: "hidden",
                    overflowY: "auto"
                }}
            >
                <TableContainer component={Paper} sx={{ mr: { xs: 0, md: 3 } }}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableBody>
                            {props?.activeSessions?.map((session) => (
                                <LoggedInSession key={session.id} loading={props.loading} row={session} remove={props.remove} removeLoading={props.removeIsLoading} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
