import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { CssBaseline, Slide } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { theme } from "./utils/theme";
import Main from "./Pages/main";
import RecoverPassword from "./Pages/recoverPassword";
import PrivateRoute from "./components/privateRoute";
import ConsentRoute from "./components/consentRoute";
import OPIframe from "./Pages/sessionIFrame";
import RPLogout from "./Pages/rpLogout";
import Clients from "./Pages/clients/clients";
import Profile from "./Pages/profile/profile";
import { AuthProvider } from "./context/AuthProvider";
import Scopes from "./Pages/scopes/scopes";
import Users from "./Pages/users/users";
import PersistLogin from "./Pages/persistLogin";
import AuthorizedApps from "./Pages/authorized-apps/authorized-apps";
import ResourceServers from "./Pages/resource-servers/resource-servers";
import Roles from "./Pages/roles/roles";
import IdentityProviders from "./Pages/identity-providers/identity-provider";
import PageNotFound from "./Pages/pageNotFound";
import ErrorPageLoader from "./Pages/errorPageLoader";
import EditProfile from "./Pages/profile/editProfile";
import SignInWithPhone from "./Pages/signIn/signInWithPhone";
import Register from "./Pages/registration/register";
import ForgetPassword from "./Pages/signIn/forgetPassword";

export const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          refetchOnWindowFocus: false,
          refetchOnWindow: false,
          retry: false,
      },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
      >
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="login" element={<SignInWithPhone />} />
                <Route path="register" element={<Register />} />
                <Route path="recoverPassword" element={<RecoverPassword />} />
                <Route path="consent" element={<ConsentRoute />} />
                <Route path="opIframe" element={<OPIframe />} />
                <Route path="rpLogout" element={<RPLogout />} />
                <Route path="error" element={<ErrorPageLoader />} />
                <Route path="forget-password" element={<ForgetPassword />} />
                <Route element={<PersistLogin />}>
                  <Route
                    path="edit-profile"
                    element={
                      <PrivateRoute>
                        <EditProfile />
                      </PrivateRoute>
                    } />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute >
                        <Main />
                      </PrivateRoute>
                    }
                  >
                    <Route index element={<Profile />} />
                    <Route path="authorized-apps" element={<AuthorizedApps />} />
                    <Route path="users" element={<Users />} />
                    <Route path="roles" element={<Roles />} />
                    <Route path="scopes" element={<Scopes />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="resource-servers" element={<ResourceServers />} />
                    <Route path="identity-providers" element={<IdentityProviders />} />
                  </Route>
                </Route>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;