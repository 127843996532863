import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ProfilePicture from "./profile-picture";
import ChangePhone from "./changePhone";
import DeleteMyAccount from "./deleteAccount";

function EditProfile() {
    // USESTATE
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("");
    const [openChangePhone, setOpenChangePhone] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        mode: "onChange"
    });

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // MUTATION & QUERY
    const { mutate, isLoading, data, error } = useMutation((formData) => {
        return axiosPrivate.put(`/profile`, formData);
    });

    const { data: profile, isFetching, refetch } = useQuery(["edit_profile"], () => {
        return axiosPrivate.get(`/profile`);
    },
        {
            refetchOnWindowFocus: false,
            enable: false
        }
    );

    // HELPERS
    const onSubmit = (profileData) => {
        const dataToBeSent = file !== "" ? { ...profileData, profile_picture: file } : profileData;
        mutate(dataToBeSent);
    }

    // USEEFFECT
    useEffect(() => {
        if (profile) {
            for (var key in profile?.data?.data) {
                if (profile?.data?.data.hasOwnProperty(key)) {
                    setValue(key, profile?.data?.data[key]);
                }
            }
        }
    }, [setValue, profile]);

    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully updated.`, {
                variant: "success",
            });
            window.location.href = `${params?.redirect_uri}?edit_profile=editProfile`
        }
    }, [data, enqueueSnackbar, params?.redirect_uri]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                    }
                );
            }
        }
    }, [enqueueSnackbar, error]);

    // RENDER
    return isFetching ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "100vh"
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    ) : (
        <>
            <Dialog
                open={true}
                sx={{
                    "& .MuiDialog-paper": {
                        boxShadow: "0px 1px 1px 1px white",
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "white",
                    },
                }}
                PaperProps={{
                    sx: {
                        width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" },
                        height: "auto",
                    },
                }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <DialogContent
                    sx={{
                        px: 0,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <ProfilePicture
                            page="edit_profile"
                            edit={true}
                            isOpen={open}
                            setIsOpen={setOpen}
                            setFile={setFile}
                            refetch={refetch}
                            imageSrc={file !== "" ? file : profile?.data?.data?.profile_picture}
                        />
                        <Controller
                            name="first_name"
                            control={control}
                            rules={{
                                required: "First Name is required",
                            }}
                            render={({ field }) => (
                                <TextField
                                    label="First Name"
                                    autoComplete="first_name"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.first_name}
                                    helperText={
                                        errors?.first_name
                                            ? errors.first_name.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor:
                                                    "secondary.main",
                                            },
                                        },
                                        "& label.Mui-focused": {
                                            color: "secondary.main",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="middle_name"
                            control={control}
                            rules={{
                                required: "Middle Name is required",
                            }}
                            render={({ field }) => (
                                <TextField
                                    label="Middle Name"
                                    variant="outlined"
                                    autoComplete="middle_name"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.middle_name}
                                    helperText={
                                        errors?.middle_name
                                            ? errors.middle_name.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor:
                                                    "secondary.main",
                                            },
                                        },
                                        "& label.Mui-focused": {
                                            color: "secondary.main",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="last_name"
                            control={control}
                            rules={{
                                required: "Last Name is required",
                            }}
                            render={({ field }) => (
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    autoComplete="last_name"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.last_name}
                                    helperText={
                                        errors?.last_name
                                            ? errors.last_name.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor:
                                                    "secondary.main",
                                            },
                                        },
                                        "& label.Mui-focused": {
                                            color: "secondary.main",
                                        },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    autoComplete="email"
                                    margin="normal"
                                    fullWidth
                                    error={!!errors?.email}
                                    helperText={
                                        errors?.email
                                            ? errors.email.message
                                            : null
                                    }
                                    size="small"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor:
                                                    "secondary.main",
                                            },
                                        },
                                        "& label.Mui-focused": {
                                            color: "secondary.main",
                                        },
                                    }}
                                    {...field}
                                    disabled={!!(profile?.data?.data?.email)}
                                />
                            )}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                bgcolor: "#e9e9e9",
                                width: "100%",
                                my: 1,
                                py: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "medium",
                                    textAlign: "center",
                                    ml: 2,
                                }}
                            >
                                {profile?.data?.data?.phone}
                            </Typography>
                            <Grid container justifyContent="flex-end">
                                <Box>
                                    <Button
                                        onClick={() => setOpenChangePhone(true)}
                                        sx={{
                                            bgcolor: "#2a2a2a",
                                            borderColor: "#2a2a2a",
                                            borderRadius: 5,
                                            mx: 1,
                                        }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                color: "primary.paper",
                                                fontWeight: "bold",
                                                textTransform: "none",
                                            }}
                                        >
                                            Change Phone
                                        </Typography>
                                    </Button>
                                    <ChangePhone
                                        open={openChangePhone && (profile?.data?.data?.phone !== undefined)}
                                        phone={profile?.data?.data?.phone}
                                        refetch={refetch}
                                        setOpen={setOpenChangePhone}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        px: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Button
                            fullWidth
                            size="large"
                            sx={{
                                bgcolor: "black",
                                ":hover": { bgcolor: "black" },
                                textTransform: "none",
                                borderRadius: 6,
                                mb: { xs: 1, md: 0 },
                                mr: { xs: 0, md: 2 },
                            }}
                            type="submit"
                            disabled={isLoading}
                        >
                            <Typography sx={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>
                                Save
                            </Typography>
                            {isLoading && data && (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: "#fff",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            sx={{
                                bgcolor: "#e4e4e4",
                                ":hover": { bgcolor: "#e4e4e4" },
                                textTransform: "none",
                                borderRadius: 6,
                            }}
                            onClick={() => setOpenDeleteAccount(true)}
                        >
                            <Typography sx={{ color: "black", fontSize: 14, fontWeight: "bold" }}>
                                Delete My Account
                            </Typography>
                            {isLoading && data && (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: "secondary.main",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <DeleteMyAccount
                params={params}
                openDeleteAccount={openDeleteAccount}
                setOpenDeleteAccount={setOpenDeleteAccount}
            />
        </>
    )
}
export default EditProfile;