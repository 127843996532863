import { Button, CircularProgress, Container, Divider, FormLabel, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Identity Providers Details View Component
const IdentityProvidersDetails = ({ handleViewModalClose, identityProviderId }) => {
    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // QUERY
    const { isLoading, data, isFetching, error } = useQuery(["identity-providers-detail"], () => {
        return axiosPrivate.get(`/identityProviders/${identityProviderId}`);
    },
        {
            refetchOnWindowFocus: false,
            enable: false
        }
    );

    // USEFFECTS
    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);
    
    // Identity Provider Data
    const IPData = [
        {
            label: "Name:",
            value: data?.data?.data?.name
        },
        {
            label: "Logo URI:",
            value: data?.data?.data?.logo_uri
        },
        {
            label: "Client ID:",
            value: data?.data?.data?.client_id
        },
        {
            label: "Client Secret:",
            value: data?.data?.data?.client_secret
        },
        {
            label: "Redirect URI:",
            value: data?.data?.data?.redirect_uri
        },
        {
            label: "Authorization URI:",
            value: data?.data?.data?.authorization_uri
        },
        {
            label: "Token Endpoint URI:",
            value: data?.data?.data?.token_endpoint_uri
        },
        {
            label: "User Info Endpoint URI:",
            value: data?.data?.data?.user_info_endpoint_uri
        }
    ]
    
    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    bgcolor: "primary.paper",
                    borderRadius: 4
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        my: 3,
                        mx: 2
                    }}
                >
                    <Box sx={{ mb: 1 }}>
                        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
                            View Identity Provider Details
                        </Typography>
                    </Box>
                    <Divider />
                    {
                        isLoading || isFetching ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 3,
                                    mb: 2
                                }}
                            >
                                <CircularProgress
                                    size={30}
                                    sx={{
                                        color: "secondary.main",
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                {IPData.map((IP)=>
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "flex-start", my: 0.5, py: 1 }}>
                                        <FormLabel sx={{flex:1}}>{IP.label}</FormLabel>
                                        <Typography 
                                            sx={{
                                                flex:2,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                            }}
                                        >
                                            {IP.value}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </>
                                )}
                            </>
                        )}
                    <Box
                        sx={{
                            mt: 1.5,
                            display: 'flex'
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                ":hover": { bgcolor: "#FFAA00" },
                                backgroundColor: "#1c2526",
                                color: "#fff",
                                textTransform: "none"
                            }}
                            onClick={handleViewModalClose}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Container >
        </Grid >
    );
};
export default IdentityProvidersDetails;