import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        const response = await axios.get('/refreshToken', {
           withCredentials: true
        });
        setAuth(prev => {
            prev = response.data.data;
            return prev;
        });
        return response.data.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;
