import { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import logo from "../../utils/images/ridePlus.png";
import { queryClient } from "../../App";
import CustomPinInput from "../../components/customPinInput";

function RequestOtp({ openDialog, userInput, setOpenDialog }) {
  const navigate = useNavigate()

  const { setAuth, setPersist, redirectUri } = useContext(AuthContext)
  const [requestOtp, setRequestOtp] = useState(false)
  const [inputValue, setInputValue] = useState()


  const { enqueueSnackbar } = useSnackbar()

  const {
    isFetching: refecthLoading,
    data: otpData,
    error: otpError,
  } = useQuery(["refetch_otp"], () =>
    axios.get(
      '/otp',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          type: "login",
          phone: userInput
        }
      }),
    {
      onSuccess: () =>
        setRequestOtp(false),
      onError: () =>
        setRequestOtp(false),
      enabled: requestOtp,
    }
  );

  const { isLoading, mutate, error } = useMutation((auth) => {
    return axios.post("/login", auth, { withCredentials: true });
  });

  // HELPERS
  const onSubmit = async (formData) => {
    const inputData = { ...formData, phone: userInput }

    setPersist(true);
    setRequestOtp(false)

    return mutate(inputData, {
      onSuccess: (data) => {
        setAuth(data?.data?.data);
        redirectUri ? navigate(redirectUri, { replace: true }) : navigate("/");
      },
    });
  };

  useEffect(() => {
    setRequestOtp(false)
  }, [otpData])
  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
    queryClient.clear();
  }, [enqueueSnackbar, error]);
  useEffect(() => {
    if (otpError) {
      if (otpError?.response?.data?.error?.field_error?.length > 0) {
        otpError?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          otpError?.response?.data?.error?.message ||
          otpError?.message ||
          "Network Error!",
          {
            variant: "error",
          }
        );
      }
      setRequestOtp(false)
    }
  }, [enqueueSnackbar, otpError, setRequestOtp,]);
  return (
    <Dialog
      open={openDialog}
      sx={{
        "& .MuiDialog-paper": {
          boxShadow: "0px 1px 1px 1px white",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "white",
        },
      }}
      PaperProps={{ sx: { width: { lg: "25%", md: "40%", xs: "100%", sm: "45%" }, height: "auto" } }}
    >
      <DialogContent
        sx={{
          px: 0
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            mb: 2,
          }}
        >
          <Avatar
            sx={{ width: "140px", height: "100px", m: 1, borderRadius: 0 }}
            src={logo}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 16,
            pt: 1,
            mb: 2,
          }}
        >
          Please fill the code sent to your phone number {userInput} via SMS
        </Typography>
        <CustomPinInput handleOnSubmit={onSubmit} setInputValue={setInputValue} />
        <Button
          size="small"
          variant="text"
          type="text"
          sx={{
            ":hover": { bgcolor: "#fff" },
            backgroundColor: "#fff",
            color: "primary.main",
            variant: "body2",
            mt: 1,
          }}
          onClick={() => {
            setRequestOtp(true)
          }}
          disabled={refecthLoading}
        >
          Resend OTP
          {refecthLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "secondary.main",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Button>
      </DialogContent>
      <DialogActions
        sx={{
          px: 0
        }}
      >
        <Box
          sx={{
            my: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ":hover": { bgcolor: "#e4e4e4" },
              backgroundColor: "#e4e4e4",
              color: "#1c2526",
              fontWeight: "bold",
              borderRadius: 6,
              px: 1,
              mr: 1.5,
            }}
            onClick={() => setOpenDialog(false)}
          >
            Back
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{
              ":hover": { bgcolor: "secondary.main" },
              backgroundColor: "secondary.main",
              "&:disabled": {
                backgroundColor: "secondary.main",
              },
              fontWeight: "bold",
              borderRadius: 6,
              px: 1,
              ml: 1.5,
            }}
            onClick={() => onSubmit({ otp: inputValue })}
            disabled={isLoading || refecthLoading || inputValue?.length !== 6}
          >
            <Typography sx={{ color: "#fff" }}>
              Continue
            </Typography>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
export default RequestOtp