import { useState, useRef, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal
} from "@mui/material";
import CustomToolbar from '../customToolbar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import IdentityProvidersUpdate from './update-identity-provider';
import { useMutation, useQuery } from 'react-query';
import HasPermission from '../../utils/access';
import IdentityProvidersDetails from './identity-providers-details';
import { useSnackbar } from 'notistack';
import Error from '../error';

// Identity Providers List View Component
function IdentityProviders() {
    // USESTATES & USEREFS
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [update, setUpdate] = useState(false);
    const [view, setView] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [searchFocus, setSearchFocus] = useState(true);
    const [filterFocus, setFilterFocus] = useState(true);
    const [columnField, setColumnField] = useState();
    const [operatorValue, setOperatorValue] = useState();
    const [values, setValue] = useState();
    const [sortModel, setSortModel] = useState([
        { field: "name", sort: "asc" },
    ]);

    const identityProviderId = useRef();
    const IdentityProviderName = useRef();
    let identityProviderData = useRef({});

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // QUERY & MUTATION   
    const filterOptions = { columnField, operatorValue, values }
    const {
        isLoading: clientsDataLoading,
        data: clientsData,
        refetch,
        error: fetchIPError
    } = useQuery(["identity-providers", page, perPage, filterOptions, sortModel], () => {
        return axiosPrivate.get(`/identityProviders`, {
            params: {
                page: page,
                per_page: perPage,
                sort: JSON.stringify(sortModel),
                filter: (
                    filterOptions.columnField === undefined ||
                    filterOptions.operatorValue === undefined ||
                    filterOptions.values === undefined
                ) ? [] : JSON.stringify([{
                    column_field: filterOptions?.columnField,
                    operator_value: filterOptions?.operatorValue,
                    value: filterOptions?.values
                }])
            },
        });
    },
        {
            refetchOnWindowsFocus: false,
            enable: false
        }
    );
    const { mutate: deleteClient, isLoading, data, error } = useMutation((id) => {
        return axiosPrivate.delete(`/identityProviders/${id}`);
    });

    // EVENT HANDLERS & HELPERS
    const handleEdit = (identityProvider) => {
        identityProviderId.current = identityProvider.id;
        identityProviderData.current = identityProvider;
        setUpdate(true);
        setOpen(true);
        return identityProviderData.current;
    }
    const handleDrawer = () => {
        setOpen(false);
        setUpdate(false);
    }
    const handleView = (id) => {
        identityProviderId.current = id;
        setView(true);
        setOpen(true);
    }
    const handleDelete = () => {
        deleteClient(identityProviderId.current);
    }
    const handleClick = (identityProvider) => {
        setOpenDialog(true);
        identityProviderId.current = identityProvider.id;
        IdentityProviderName.current = identityProvider.name;
    }
    const handleViewModalClose = () => {
        setOpen(false);
        setView(false);
    }
    const handleModalClose = () => {
        setOpen(false);
    }
    const handleOnFilterFocus = () => {
        setFilterFocus(true)
        setSearchFocus(false)
    }
    const handleOnSearchFocus = () => {
        setFilterFocus(false)
        setSearchFocus(true)
    }
    const onFilterChange = useCallback((filterModel) => {
        if (filterFocus && filterModel?.items.length !== 0) {
            filterModel.quickFilterValues = [];
            setColumnField(filterModel?.items[0]?.columnField);
            setOperatorValue(filterModel?.items[0]?.operatorValue);
            setValue(filterModel?.items[0]?.value);
        } else {
            filterModel.items = [];
            setColumnField("*");
            setOperatorValue("contains");
            setValue(JSON.stringify({
                columns: [
                    "name",
                    "logo_url",
                    "authorization_uri",
                    "client_id",
                    "client_secret",
                    "redirect_uri",
                    "token_endpoint_url",
                    "user_info_endpoint_url"
                ],
                value: filterModel?.quickFilterValues[0]
            }));
        }
    }, [filterFocus]);
    const onSortChange = useCallback((newSortModel) => {
        setSortModel(newSortModel);
    }, []);

    // USEEFFECT 
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully deleted.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOpenDialog(false);
            refetch();
        }
    }, [data, enqueueSnackbar, refetch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);
    useEffect(() => {
        if (values !== undefined) {
            setPage(0);
        }
        if (values?.value !== undefined) {
            if (JSON.parse(values)?.value || values) {
                setPage(0);
            }
        }
    }, [values])

    // DATA STRUCTURE
    const columns = [
        {
            field: 'created_at', headerName: 'Created At', type: 'date', flex: 0.2,
            valueGetter: (params) =>
                moment(params?.row?.created_at).format("llll")
        },
        { field: 'name', headerName: 'Name', flex: 0.2 },
        { field: 'logo_uri', headerName: 'Logo Uri', flex: 0.2 },
        { field: 'client_id', headerName: 'Client ID', flex: 0.2 },
        { field: 'client_secret', headerName: 'Clieint Secret', flex: 0.2 },
        { field: 'redirect_uri', headerName: 'Redirect Uri', flex: 0.2 },
        { field: 'authorization_uri', headerName: 'Authorization Uri', flex: 0.2 },
        { field: 'token_endpoint_uri', headerName: 'Token Endpoint Uri', flex: 0.2 },
        { field: 'user_info_endpoint_uri', headerName: 'User Info Endpoint Uri', flex: 0.2 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 0.3,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Box sx={{ flex: 0.2 }}>
                        <Button
                            sx={{ minWidth: 24, p: 1, color: 'black' }}
                            onClick={() => handleView(params.row.id)}
                            disabled={!HasPermission("get_identity_provider")}
                        >
                            <Visibility />
                        </Button>
                        <Button
                            sx={{ minWidth: 24, p: 1, color: 'black' }}
                            onClick={() => handleEdit(params.row)}
                            disabled={!HasPermission("update_identity_provider")}
                        >
                            <Edit />
                        </Button>
                        <Button
                            sx={{ minWidth: 24, p: 1, color: 'red' }}
                            onClick={() => handleClick(params.row)}
                            disabled={!HasPermission("delete_identity_provider")}
                        >
                            <Delete />
                        </Button>
                        <div>
                            <Dialog
                                id="delete-identity-providers-by-id"
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                BackdropProps={{ invisible: true }}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Confirm delete operation"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want delete identity provider `{IdentityProviderName.current}`?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => setOpenDialog(false)}
                                        sx={{ bgcolor: '#707070', color: 'black' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => handleDelete()}
                                        disabled={isLoading}
                                        sx={{ bgcolor: 'red', color: 'black' }}
                                    >
                                        Delete
                                        {isLoading && (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: "secondary.main",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Box>
                );
            },
        },
    ];

    // CUSTOM COMPONENT
    function DataGridCustomToolbar() {
        return (
            <CustomToolbar
                searchFocus={searchFocus}
                handleOpen={() => {
                    setOpen(true);
                    setSearchFocus(false);
                    setUpdate(false);
                }}
                handleOnFilterFocus={handleOnFilterFocus}
                handleOnSearchFocus={handleOnSearchFocus}
                page="Identity Providers"
                permission="create_identity_provider"
            />
        )
    }

    // RENDERING
    return fetchIPError ? (
        <Error error={fetchIPError?.response?.data?.error} />
    ) : (
        <Box
            sx={{
                backgroundColor: "#fff",
                flex: 5,
                p: 0
            }}
        >
            <Modal
                open={open}
                children={
                    view ? (
                        <IdentityProvidersDetails
                            handleViewModalClose={handleViewModalClose}
                            identityProviderId={identityProviderId.current}
                        />
                    ) : (
                        <IdentityProvidersUpdate
                            handleModalClose={handleModalClose}
                            isNew={!update}
                            identityProviderData={identityProviderData.current}
                            refetch={refetch}
                        />
                    )
                }
                onClose={handleDrawer}
            />
            <DataGrid
                sx={{
                    pt: 2,
                    px: 2,
                    fontSize: 12,
                    overflow: 'hidden',
                    resize: 'both'
                }}
                rows={clientsData?.data?.data || []}
                columns={columns}
                loading={clientsDataLoading}
                autoHeight
                pagination
                page={page}
                pageSize={perPage}
                paginationMode="server"
                rowsPerPageOptions={[5, 10, 25, 100]}
                rowCount={clientsData?.data?.meta_data?.total}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
                sortingMode="server"
                sortModel={sortModel}
                sortingOrder={["desc", "asc"]}
                onSortModelChange={onSortChange}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                components={{ Toolbar: DataGridCustomToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 1000 }
                    }
                }}
            />
        </Box>
    )
}
export default IdentityProviders;