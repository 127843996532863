import { SHA256 } from "crypto-js";
import Cookies from "js-cookie";
import { useEffect } from "react";

// Fetch opbs(openId provider browser state) cookie
const getOpUserAgentState = () => {
  return Cookies.get("opbs");
}

// Recieve relying party postmessage request
const receiveMessage = (e) => { // e.data has client_id and session_state

  if (!e.data) {
    e.source.postMessage('error', e.origin)
    return
  }
  if (e.data === "") {
    e.source.postMessage('error', e.origin)
    return
  }
  if (typeof e.data !== "string") {
    e.source.postMessage('error', e.origin)
    return
  }
  if (e.data.lastIndexOf(' ') === -1) {
    e.source.postMessage('error', e.origin)
    return
  }

  let client_id = e.data.substr(0, e.data.lastIndexOf(' '));
  let session_state = e.data.substr(e.data.lastIndexOf(' ') + 1);

  if (session_state.lastIndexOf('.') === -1) {
    e.source.postMessage('error', e.origin)
    return
  }

  let salt = session_state.split('.')[1];
  if (client_id === "" || session_state === "" || salt === "") {
    e.source.postMessage('error', e.origin)
    return
  }
  // TODO: unexpected origin

  let opuas = getOpUserAgentState();
  let ss = SHA256(client_id + ' ' + e.origin + ' ' +
    opuas + ' ' + salt) + "." + salt;

  let stat = '';
  if (session_state === ss) {
    stat = 'unchanged';
  } else {
    stat = 'changed';
  }

  e.source.postMessage(stat, e.origin);
};

// OpenId Provider Iframe component
const OPIframe = () => {
  // USEEFECT
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, [])

  // RENDER
  return (
    <div>
      You shouldn't see me!
    </div>
  )
}
export default OPIframe;
