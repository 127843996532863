import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFAA00",
      paper: "#FFF",
      remove: "#ed3f3c"
    },
    secondary: {
      main: "#1D2426",
    },
    google: {
      main: "#4185f4"
    },
    facebook: {
      main: "#1877F2"
    }
  }
});
