import { createContext, useEffect, useMemo, useState } from "react";
import axios from "../api/axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState([]);
  const [redirectUri, setRedirectUri] = useState();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const response = await axios.get("/refreshToken", {
          withCredentials: true,
        });
        setAuth(response?.data?.data);
      } catch (error) {
        setAuth({});
      }
    };
    getAccessToken();
  }, []);

  const value = useMemo(
    () => ({
      auth,
      setAuth,
      persist,
      setPersist,
      permissions,
      setPermissions,
      role,
      setRole,
      redirectUri,
      setRedirectUri
    }),
    [auth, permissions, persist, redirectUri, role]
  )
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;