import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Add, Delete } from "@mui/icons-material";

// Resource Server Update Component
function ResourceServersUpdate({
  handleModalClose,
  refetch
}) {
  // HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: "onChange" });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "scopes"
  });

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // MUTATION
  const { mutate, isLoading, data, error } = useMutation((formData) => {
    return axiosPrivate.post(`/resourceServers`, formData);
  });

  // HELPERS
  const onSubmit = formData => {
    mutate(formData);
  }

  // USEEFFECT 
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully created a new resource server.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      handleModalClose();
      refetch();
    }
  }, [data, enqueueSnackbar, handleModalClose, refetch]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      }
    }
  }, [enqueueSnackbar, error]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          bgcolor: "primary.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            m: 2,
            maxHeight: "80vh"
          }}
        >
              <Typography sx={{ fontSize: 26, fontWeight: 'bold', textAlign: "center" }}> Add New Resource Server</Typography>
         
          <Divider />
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required",
            }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                label="Name"
                fullWidth
                autoComplete="name"
                error={!!errors?.name}
                helperText={errors?.name ? errors.name.message : null}
                size='small'
                sx={{
                  mt: 1.5,
                  mb: 1.5
                }}
                {...field}
              />
            )}
          />
          <Divider />
          <Typography sx={{ fontSize: 18, fontWeight: 'bold', mt: 1 }}>
            Scopes
          </Typography>
          <Box
            sx={{
              maxHeight: "70vh",
              overflowY: "visible",
              overflowX: "hidden",
            }}
          >
            {fields.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mr: 1
                }}
              >
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    width: "100%"
                  }}
                >
                  <Controller
                    key={item.id}
                    name={`scopes[${index}].name`}
                    control={control}
                    rules={{
                      required: "Scope name can't be empty.",
                    }}
                    render={({ field }) =>
                      <TextField
                        key={`scopes[${index}].name`}
                        id="scope_name"
                        variant="outlined"
                        multiline
                        label={`Scope ${index + 1} name`}
                        margin="normal"
                        fullWidth
                        error={!!errors?.scopes?.[index]?.name}
                        helperText={errors?.scopes?.[index]?.name ? errors.scopes?.[index]?.name?.message : null}
                        size='small'
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          mr: 1,
                          flex: 1
                        }}
                        {...field}
                      />
                    }
                  />
                  <Controller
                    key={index}
                    name={`scopes[${index}].description`}
                    control={control}
                    rules={{
                      required: "Scope description can't be empty.",
                    }}
                    render={({ field }) =>
                      <TextField
                        key={`scopes[${index}].description`}
                        id="scope_description"
                        variant="outlined"
                        multiline
                        label={`Scope ${index + 1} Description`}
                        margin="normal"
                        fullWidth
                        error={!!errors?.scopes?.[index]?.description}
                        helperText={errors?.scopes?.[index]?.description ? errors.scopes?.[index]?.description?.message : null}
                        size='small'
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          mr: -2,
                          flex: 2
                        }}
                        {...field}
                      />
                    }
                  />
                  <Button
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      mr: -1.5
                    }}
                    onClick={() => remove(index)}
                  >
                    <Delete sx={{ color: "secondary.main" }} />
                  </Button>
                </Box>
              </Box>
            ))}
            <Box
              sx={{
                boxShadow: 1,
                width: "17%",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                mt: 1.5,
                mb: 2
              }}
            >
              <Button
                sx={{
                  color: "secondary.main",
                }}
                onClick={() => append()}
              >
                <Add sx={{ mr: 0.5 }} />
                Add Scope
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                ":hover": { bgcolor: "#FFAA00" },
                backgroundColor: "#1c2526",
                color: "#fff",
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                bgcolor: "#fdbc3d",
                ":hover": { bgcolor: '#1c2526' }
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <Typography sx={{ color: "#fff" }}>{'Add'}</Typography>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "secondary.main",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default ResourceServersUpdate;