import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import logo from "../utils/images/ridePlus.png";
import { useNavigate } from "react-router-dom";
import { Dialog, Divider, Grid } from "@mui/material";

function RecoverPassword() {
    const navigate = useNavigate();

    // USESTATE
    const [openDrawer, setOpenDrawer] = useState(false);
    const [email, setEmail] = useState("");
    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    // HELPERS
    const onSubmit = (data) => {
        setEmail(data ? data.email : null);
    }

    const handleClick = () => {
        navigate("/login");
    }

    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: 1,
                        p: "5%",
                    }}
                >
                    <Avatar
                        sx={{ width: "100px", height: "80px", m: 1, borderRadius: 0 }}
                        src={logo}
                    />
                    <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                        Recover Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                        <Divider />
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    sx={{
                                        mt: 1.5,
                                        mb: 0.5
                                    }}
                                    fullWidth
                                    size="small"
                                    label="Email Address"
                                    autoComplete="email"
                                    autoFocus
                                    helperText={errors?.email ? errors?.email?.message : ""}
                                    error={errors?.email}
                                    {...field}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            size="medium"
                            variant="contained"
                            sx={{
                                mt: 1,
                                mb: 2,
                                textTransform: "none",
                                ":hover": { bgcolor: "primary.main" },
                            }}
                            onClick={() => setOpenDrawer(true)}
                        >
                            Email me a recovery link
                        </Button>
                        {openDrawer && email &&
                            <Dialog open={openDrawer} fullScreen>
                                <Grid
                                    container
                                    sx={{
                                        minHeight: "100vh",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Container component="main" maxWidth="xs">
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                boxShadow: 1,
                                                px: 2,
                                            }}
                                        >
                                            <Avatar
                                                sx={{ width: "100px", height: "80px", m: 1, borderRadius: 0 }}
                                                src={logo}
                                            />
                                            <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                                                Recover Password
                                            </Typography>
                                            <Box component="div" sx={{ my: 2 }}>
                                                <Divider />
                                                <Typography sx={{ fontWeight: 20, my: 1 }}>
                                                    If your account {email} exists, an email will be sent with further instructions.
                                                </Typography>
                                                <Divider />
                                                <Box
                                                    sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            ":hover": { bgcolor: "#FFAA00" },
                                                            backgroundColor: "#1c2526",
                                                            color: "#fff",
                                                            textTransform: "none"
                                                        }}
                                                        onClick={handleClick}
                                                    >
                                                        Back to login
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Container>
                                </Grid>
                            </Dialog>}
                        <Divider />
                        <Box
                            sx={{
                                my: 1,
                                display: 'flex',
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    ":hover": { bgcolor: "#1c2526" },
                                    backgroundColor: "#1c2526",
                                    color: "#fff",
                                    mt: 1,
                                }}
                                onClick={handleClick}
                            >
                                Back to Login
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Grid>
    );
}
export default RecoverPassword;
