import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Divider, Grid, Slide } from "@mui/material";
import logo from "../../utils/images/ridePlus.png";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import axios from "../../api/axios";
import Consent from "../consent/consent";
import RegisterWithEmail from "../registration/registerWithEmail";
import ForgetPassword from "./forgetPassword";

function SignInWithEmail({ consentId }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false)
  const [openForgetPassword, setOpenForgotPassword] = useState(false)

  // USEFORM HOOK
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange"
  });

  const { auth, setAuth, persist, setPersist, redirectUri } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation((auth) => {
    return axios.post("/login", auth, { withCredentials: true });
  });

  const {
    data: identityProviders
  } = useQuery(["registered_identity_providers"], () => {
    return axios.get(`/registeredIdentityProviders`, {
      params: {
        page: -1
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  // HELPERS
  const onSubmit = async (formData) => {
    setPersist(true);
    return mutate(formData, {
      onSuccess: (data) => {
        setAuth(data?.data?.data);
        return <Consent consentId={consentId} />
      },
      onError: (error) => {
        console.error({
          error,
          msg: error?.response?.data?.error?.message,
          default: error?.message
        });
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            TransitionComponent: Slide,
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      },
    });
  };

  // RENDER
  if(openForgetPassword) return <ForgetPassword open={openForgetPassword} setOpen={setOpenForgotPassword}/>

  return auth?.access_token ? (
    redirectUri ?
      <Consent consentId={consentId} /> : navigate("/")
  ) : (
    open ?
      <RegisterWithEmail setOpenRegister={setOpen} /> :
      <Grid
        container
        sx={{
          minHeight: "600px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 1,
              p: 2,
            }}
          >
            <Avatar
              sx={{ width: "140px", height: "100px", m: 1, borderRadius: 0 }}
              src={logo}
            />
            <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Sign In with Ride Plus SSO
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, mx: 1 }}>
              <Divider />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 2,
                      mb: 1
                    }}
                    fullWidth
                    size="small"
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    helperText={errors?.email ? errors?.email?.message : ""}
                    error={errors?.email}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      my: 1
                    }}
                    fullWidth
                    size="small"
                    label="Password"
                    autoComplete="current-password"
                    type="password"
                    helperText={errors?.password ? errors?.password?.message : ""}
                    error={errors?.password}
                    {...field}
                  />
                )}
              />
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: 14,
                }}
                onClick={() => setOpenForgotPassword(true)}
              >
                Forgot password?
              </Button>
              <Button
                type="submit"
                fullWidth
                size="medium"
                variant="contained"
                sx={{
                  my: 1,
                  color: "primary.paper",
                  textTransform: "none",
                  ":hover": {
                    bgcolor: "primary.main"
                  }
                }}
                disabled={isLoading}
              >
                Sign In
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "secondary.main",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Button>
              <Box
                sx={{
                  mt: 1,
                  mb: 1,
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography sx={{ textAlign: "center", fontWeight: "medium", pr: 1 }}>
                  Don't have an account?
                </Typography>
                <Button
                  sx={{
                    color: "facebook.main",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: 16,
                  }}
                  onClick={() => setOpen(true)}
                >
                  Create Account
                </Button>
              </Box>
              {identityProviders?.data?.data?.length > 1 &&
                <>
                  <Divider> Or </Divider >
                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {identityProviders?.data?.data?.map((IP) =>
                      <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                          backgroundColor: "primary.paper",
                          textTransform: "none",
                          mb: 1.5
                        }}
                      >
                        <Avatar sx={{ width: 30, height: 30, mr: 1 }} src={IP.logo_uri} />
                        <Typography sx={{ textAlign: "center", mt: 0.5, fontSize: 14 }}>
                          Sign In With {IP.name}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </>
              }
            </Box>
          </Box>
        </Container>
      </Grid>
  );
}
export default SignInWithEmail;