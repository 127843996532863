
import { useState, useRef, useCallback, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Switch
} from "@mui/material";
import UsersUpdate from './users-update';
import CustomToolbar from '../customToolbar';
import UsersDetail from './users-detail';
import { useMutation, useQuery } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import HasPermission from '../../utils/access';
import Error from '../error';

// Users list component
function Users() {
  // USESTATES & USEREFS
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [searchFocus, setSearchFocus] = useState(true);
  const [filterFocus, setFilterFocus] = useState(true);
  const [columnField, setColumnField] = useState();
  const [operatorValue, setOperatorValue] = useState();
  const [values, setValues] = useState();
  const [sortModel, setSortModel] = useState([
    { field: "first_name", sort: "asc" },
  ]);

  const userId = useRef();
  let userData = useRef({});

  const {
    register,
    handleSubmit,
    watch
  } = useForm({
    // defaultValues: ""
  });

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // QUERY & MUTATION   
  const filterOptions = { columnField, operatorValue, values }
  const {
    isLoading: usersDataLoading,
    data: usersData,
    refetch,
    error: fetchUsersError
  } = useQuery(["users", page, perPage, filterOptions, sortModel], () => {
    return axiosPrivate.get(`/users`, {
      params: {
        page: page,
        per_page: perPage,
        sort: JSON.stringify(sortModel),
        filter: (
          filterOptions.columnField === undefined ||
          filterOptions.operatorValue === undefined ||
          filterOptions.values === undefined
        ) ? [] : JSON.stringify([{
          column_field: filterOptions?.columnField,
          operator_value: filterOptions?.operatorValue,
          value: filterOptions?.values
        }])
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );
  const {
    isLoading: rolesIsLoading,
    data: rolesData
  } = useQuery(["rolesData"], () => {
    return axiosPrivate.get(`/roles`, {
      params: {
        page: -1
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );
  const { mutate, data, error } = useMutation((user) => {
    return axiosPrivate.patch(`/users/${user.id}/status`, { status: user?.status });
  });
  const {
    mutate: updateRole,
    data: updatedRole,
    error: updateRoleError
  } = useMutation((roleData) => {
    return roleData.role === "" ?
      (
        axiosPrivate.delete(`/users/${userId.current}/role`, roleData)
      ) : (
        axiosPrivate.patch(`/users/${userId.current}/role`, roleData)
      )
  });

  // EVENT HANDLERS & HELPERS
  const handleView = (id) => {
    userId.current = id;
    setView(true);
    setOpen(true);
  }
  const onAssignRoleClick = (id) => {
    userId.current = id;
  }
  const handleDrawer = () => {
    setOpen(false);
    setView(false);
  }
  const handleModalClose = () => {
    setOpen(false);
  }
  const handleViewModalClose = () => {
    setOpen(false);
    setView(false);
  }
  const handleSwitch = (user) => {
    userId.current = user.id
    userData.current = {
      ...userData.current,
      id: user.id,
      status: user.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    }
    mutate(userData.current);
  }
  const handleOnFilterFocus = () => {
    setSearchFocus(false)
    setFilterFocus(true)
  }
  const handleOnSearchFocus = () => {
    setFilterFocus(false)
    setSearchFocus(true)
  }
  const onFilterChange = useCallback((filterModel) => {
    if (filterFocus && filterModel?.items.length !== 0) {
      filterModel.quickFilterValues = [];
      setColumnField(filterModel?.items[0]?.columnField);
      setOperatorValue(filterModel?.items[0]?.operatorValue);
      setValues(filterModel?.items[0]?.value);
    } else {
      filterModel.items = [];
      setColumnField("*");
      setOperatorValue("contains");
      setValues(JSON.stringify({
        columns: [
          "first_name",
          "middle_name",
          "last_name",
          "status",
          "phone",
          "gender",
          "email"
        ],
        value: filterModel?.quickFilterValues[0]
      }));
    }
  }, [filterFocus]);
  const onSortChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  // USEEFFECT
  useEffect(() => {
    const subscription = watch(handleSubmit(updateRole));
    return () => subscription.unsubscribe();
  }, [updateRole, handleSubmit, watch]);

  useEffect(() => {
    if (values !== undefined) {
      setPage(0);
    }
    if (values?.value !== undefined) {
      if (JSON.parse(values)?.value || values) {
        setPage(0);
      }
    }
  }, [values])

  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully changed status.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      refetch();
    }
  }, [data, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (updatedRole) {
      enqueueSnackbar(`Succesfully changed role.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      refetch();
    }
  }, [updatedRole, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
        error?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (updateRoleError) {
      if (updateRoleError?.response?.data?.error?.field_error?.length > 0) {
        updateRoleError?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          updateRoleError?.response?.data?.error?.message ||
          updateRoleError?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      }
    }
  }, [enqueueSnackbar, updateRoleError]);

  // DATA STRUCTURE
  const columns = [
    {
      field: 'created_at', headerName: 'Created At', type: 'date', flex: 0.2,
      valueGetter: (params) =>
        moment(params?.row?.created_at).format("llll")
    },
    {
      field: 'first_name', headerName: 'Name', flex: 0.2,
      valueGetter: (params) =>
        `${params?.row?.first_name} ${params?.row?.middle_name} ${params?.row?.last_name}`,
    },
    { field: "email", headerName: "Email", flex: 0.15 },
    { field: "phone", headerName: "Phone", flex: 0.15 },
    { field: "gender", headerName: "Gender", flex: 0.1 },
    {
      field: "role", headerName: "Role", flex: 0.2, filterable: false, sortable: false,
      renderCell: (params) => {
        return (
          <FormControl
            margin="normal"
            size="small"
            fullWidth
          >
            <Select
              key={params?.row?.id}
              fullWidth
              defaultValue={params.row.role}
              disabled={params.row.role === "super-user" || !HasPermission("update_user_role") || !HasPermission("revoke_user_role")}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "primary.main"
                  }
                }
              }}
              {...register("role")}
              onClick={() => onAssignRoleClick(params.row.id)}
            >
              <MenuItem value={``}>
                {"None"}
              </MenuItem>
              {rolesData?.data?.data?.map((element) => (
                <MenuItem value={element.name} key={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 0.15,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              m: 2,
              p: 2,
              bgcolor: params.row.status === 'ACTIVE' ? '#e5faea' : '#fde2e2',
              color: params.row.status === 'ACTIVE' ? '#56d154' : '#d73936',
              fontSize: 10
            }}
            id={`${params.row.id}`}
            onClick={() => handleSwitch(params.row)}
            disabled={!HasPermission("update_user_status")}
          >
            {params.row.status}
            <Switch
              checked={params.row.status === 'ACTIVE' ? true : false}
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#56d154'
                }
              }}
              size="small"
            />
          </Button>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.15,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ flex: 1 }}>
            <Button
              sx={{ minWidth: 24, p: 1, color: 'black' }}
              onClick={() => handleView(params.row.id)}
              disabled={!HasPermission("get_user")}
            >
              <Visibility />
            </Button>
          </Box>
        );
      },
    },
  ];

  // CUSTOM COMPONENT
  function DataGridCustomToolbar() {
    return (
      <CustomToolbar
        searchFocus={searchFocus}
        handleOpen={() => {
          setOpen(true);
          setSearchFocus(false);
        }}
        handleOnFilterFocus={handleOnFilterFocus}
        handleOnSearchFocus={handleOnSearchFocus}
        page="Users"
        permission="create_user"
      />
    )
  }

  // RENDERING
  return fetchUsersError ? (
    <Error error={fetchUsersError?.response?.data?.error} />
  ) : (
    <Box
      sx={{
        backgroundColor: "primary.paper",
        flex: 5,
        p: 0
      }}
    >
      <Modal
        open={open}
        children={
          view ? (
            <UsersDetail
              handleViewModalClose={handleViewModalClose}
              userId={userId.current}
            />
          ) : (
            <UsersUpdate
              handleModalClose={handleModalClose}
              refetch={refetch}
            />
          )
        }
        onClose={handleDrawer}
      />
      <DataGrid
        sx={{
          pt: 2,
          px: 2,
          fontSize: 12,
          overflow: 'hidden',
          resize: 'both'
        }}
        rows={usersData?.data?.data || []}
        columns={columns}
        loading={usersDataLoading && rolesIsLoading}
        autoHeight
        pagination
        page={page}
        pageSize={perPage}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowCount={usersData?.data?.meta_data?.total}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        sortingMode="server"
        sortModel={sortModel}
        sortingOrder={["desc", "asc"]}
        onSortModelChange={onSortChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        components={{ Toolbar: DataGridCustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 1000 }
          }
        }}
      />
    </Box>
  )
}
export default Users;