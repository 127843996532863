import { Controller, useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../utils/images/ridePlus.png";
import ProfilePicture from "../profile/profile-picture";
import { queryClient } from "../../App";
import { phoneRegex } from "../../utils/validationPattern";

function RegisterWithWeb({ setOpenRegister }) {

  // USESTATE & USEREF
  const [openDrawer, setOpenDrawer] = useState(false);
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmInputType, setConfirmInputType] = useState("password");
  const [file, setFile] = useState("");
  const [open, setOpen] = useState(false);

  const password = useRef({});
  const userData = useRef({});

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // REACT HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    mode: "onChange"
  });

  password.current = watch("password", "");

  // MUTATION & USEQUERY
  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios.post(
      '/register',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
  });
  const { isFetching: otpIsFetching, data: otpData, error: otpError, refetch } = useQuery(["otp_for_web"], () => axios.get(
    '/otp',
    {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        type: "signup",
        phone: userData.current.phone
      }
    }),
    {
      refetchOnWindowFocus: false,
      enabled: userData.current?.phone !== undefined,
      retry: false
    }
  );

  // USEEFFECT 
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`You're succesfully registered!`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      queryClient.clear();
      setOpenRegister(false);
    }
  }, [data, enqueueSnackbar, setOpenRegister]);

  useEffect(() => {
    if (otpData && userData.current.phone !== undefined) {
      enqueueSnackbar(`Code sent to your phone!`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      setOpenDrawer(false);
      queryClient.clear();
    }
  }, [enqueueSnackbar, otpData]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message ||
        error?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
    }
    if (otpError && userData.current.phone !== undefined) {
      enqueueSnackbar(
        otpError?.response?.data?.error?.message ||
        otpError?.message ||
        "Network Error!",
        {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 2000
        }
      );
    }
  }, [enqueueSnackbar, error, otpError]);

  // EVENT HANDLERS & HELPERS
  const handleClick = () => {
    setOpenRegister(false);

  };
  const handleRegister = (form) => {
    setOpenDrawer(true);
    setPhone(form?.phone);
    userData.current = form;
    refetch();
  };
  const handleSignup = (form) => {
    userData.current = { ...userData.current, otp: form.otp };
    const dataToBeSent = file !== "" ? { ...userData.current, profile_picture: file } : userData.current;
    mutate(dataToBeSent);
  };

  //RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          <Avatar
            sx={{ width: "80px", height: "65px", borderRadius: 0 }}
            src={logo}
          />
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            Create your account
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(handleRegister)}
            sx={{ mt: 1 }}
          >
            <Divider />
            <Box
              sx={{
                maxHeight: "70vh",
                overflowY: "visible",
                overflowX: "hidden",
                pr: 1
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  px: { xs: 0, md: 2 },
                }}
              >
                <ProfilePicture
                  edit={true}
                  isOpen={open}
                  setIsOpen={setOpen}
                  imageSrc={file}
                  setFile={setFile}
                />
              </Box>
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: "First Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 1.5,
                      mb: 1
                    }}
                    label="First Name"
                    fullWidth
                    autoComplete="first_name"
                    defaultValue={userData.current?.first_name}
                    error={!!errors?.first_name}
                    helperText={
                      errors?.first_name ? errors.first_name.message : null
                    }
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="middle_name"
                control={control}
                rules={{
                  required: "Middle Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    variant="outlined"
                    label="Middle Name"
                    fullWidth
                    autoComplete="middle_name"
                    defaultValue={userData.current?.middle_name}
                    error={!!errors?.middle_name}
                    helperText={
                      errors?.middle_name ? errors.middle_name.message : null
                    }
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: "Last Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    autoComplete="last_name"
                    defaultValue={userData.current?.last_name}
                    error={!!errors?.last_name}
                    helperText={errors?.last_name ? errors.last_name.message : null}
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: "Gender is required",
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    variant="outlined"
                    label="Gender"
                    fullWidth
                    select
                    size="small"
                    autoComplete="gender"
                    defaultValue={userData.current?.gender}
                    error={!!errors?.gender}
                    helperText={errors?.gender ? errors.gender.message : null}
                    {...field}
                  >
                    <MenuItem value="Male">
                      {"Male"}
                    </MenuItem>
                    <MenuItem value="Female">
                      {"Female"}
                    </MenuItem>
                  </TextField>
                )}
              />
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone is required",
                  pattern: {
                    value: phoneRegex,
                    message: "Invalid input",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    variant="outlined"
                    label="Phone"
                    fullWidth
                    autoComplete="phone"
                    defaultValue={userData.current?.phone}
                    error={!!errors?.phone}
                    helperText={errors?.phone ? errors.phone.message : null}
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    autoComplete="email"
                    defaultValue={userData.current?.email}
                    error={!!errors?.email}
                    helperText={errors?.email ? errors.email.message : null}
                    size="small"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "At least 6 characters is required."
                  },
                  maxLength: {
                    value: 32,
                    message: "The maximum characters allowed is 32."
                  }
                }}
                render={({ field }) => (
                  <TextField
                    id="password-input"
                    sx={{
                      mt: 0.5,
                      mb: 1
                    }}
                    label="Password"
                    fullWidth
                    type={inputType}
                    autoComplete="current-password"
                    defaultValue={userData.current?.password}
                    error={!!errors?.password}
                    helperText={errors?.password ? errors.password.message : null}
                    size="small"
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setShowPassword(showPassword === false ? true : false);
                              setInputType(showPassword === false ? "text" : "password");
                            }}
                          >
                            {showPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  validate: value =>
                    value === password.current || "The passwords do not match"
                }}
                render={({ field }) => (
                  <TextField
                    id="confirm-password-input"
                    sx={{
                      mt: 0.5,
                      mb: 1.5
                    }}
                    label="Confirm Password"
                    fullWidth
                    type={confirmInputType}
                    defaultValue={userData.current?.confirm_password}
                    autoComplete="confirm-current-password"
                    error={!!errors?.confirm_password}
                    helperText={
                      errors?.confirm_password
                        ? errors.confirm_password.message
                        : null
                    }
                    size="small"
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setShowConfirmPassword(showConfirmPassword === false ? true : false);
                              setConfirmInputType(showConfirmPassword === false ? "text" : "password");
                            }}
                          >
                            {showConfirmPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                mt: 1.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                alignItems
                size="small"
                sx={{ bgcolor: "#1c2526", ":hover": { bgcolor: "#fdbc3d" } }}
                onClick={handleClick}
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>Back</Typography>
              </Button>
              <Button
                alignItems
                size="small"
                sx={{ bgcolor: "#fdbc3d", ":hover": { bgcolor: "#1c2526" } }}
                type="submit"
              >
                <Typography sx={{ color: "#fff", fontSize: 14 }}>
                  Next
                  {otpIsFetching && userData.current?.phone !== undefined && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "secondary.main",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Typography>
              </Button>
              {openDrawer && !otpIsFetching && !otpError &&
                <Dialog open={openDrawer} fullScreen>
                  <Grid
                    container
                    sx={{
                      minHeight: "100vh",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Container component="main" maxWidth="xs">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          boxShadow: 1,
                          p: 2,
                        }}
                      >
                        <Avatar
                          sx={{ width: "100px", height: "80px", m: 1 }}
                          src={logo}
                        />
                        <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: 'center' }}>
                          Enter a code
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit(handleSignup)} sx={{ mt: 1 }}>
                          <Divider />
                          <Typography sx={{ fontWeight: "medium", fontSize: 16, pt: 1 }}>
                            Please fill the code sent to your phone number: {phone} via SMS.
                          </Typography>
                          <Controller
                            name="otp"
                            control={control}
                            rules={{
                              required: "Code is required"
                            }}
                            render={({ field }) => (
                              <TextField
                                sx={{
                                  mt: 1.5,
                                  mb: 1
                                }}
                                fullWidth
                                size="small"
                                label="Code"
                                autoComplete="otp"
                                autoFocus
                                helperText={errors?.otp ? errors?.otp?.message : ""}
                                error={errors?.otp}
                                {...field}
                              />
                            )}
                          />
                          <Divider />
                          <Box
                            sx={{
                              mt: 1,
                              display: 'flex',
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                ":hover": { bgcolor: "#FFAA00" },
                                backgroundColor: "#1c2526",
                                color: "#fff",
                              }}
                              onClick={() => setOpenDrawer(false)}
                            >
                              Back
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              type="submit"
                              sx={{
                                ":hover": { bgcolor: "#1c2526" },
                                backgroundColor: "#FFAA00",
                                color: "#fff",
                              }}
                              disabled={isLoading}
                            >
                              Submit
                              {isLoading && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "secondary.main",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Container>
                  </Grid>
                </Dialog>}
            </Box>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default RegisterWithWeb;