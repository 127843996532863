import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";

// Scopes Update Component
function ScopesUpdate({
  handleModalClose,
  scopeData,
  refetch,
  isNew
  /* 
    If isNew is true the form is used to add a new scope, 
    if isNew is false the form is used to update an existing scope
  */
}) {
  // HOOK FORM
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: isNew ? {} : scopeData
  });

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // SNACKBAR
  const { enqueueSnackbar } = useSnackbar();

  // MUTATION & QUERY
  const { data: resourceServers } = useQuery(["resource_servers_list"], () => {
    return axiosPrivate.get(`/resourceServers`, {
      params: {
        page: -1
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );
  const { mutate, isLoading, data, error } = useMutation((formData) => {
    return axiosPrivate.post(`/oauth/scopes`, formData);
  });

  const {
    mutate: update,
    isLoading: updateIsLoading,
    data: updateData,
    error: updateError
  } = useMutation((formData) => {
    return axiosPrivate.put(`/oauth/scopes/${scopeData.name}`, formData);
  });

  // HELPERS
  const onSubmit = formData => {
    isNew ? (
      mutate(formData)
    ) : (
      update(formData)
    )
  }

  // USEEFFECT 
  useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully created a new scope.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      handleModalClose();
      refetch();
    }
    if (updateData) {
      enqueueSnackbar(`Succesfully updated.`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      handleModalClose();
      refetch();
    }
  }, [data, enqueueSnackbar, handleModalClose, refetch, updateData]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.field_error?.length > 0) {
        error?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 2000
          }
        );
      }
    }
    if (updateError) {
      if (updateError?.response?.data?.error?.field_error?.length > 0) {
        updateError?.response?.data?.error?.field_error?.map((msg) => {
          return enqueueSnackbar(msg.description || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          updateError?.response?.data?.error?.message ||
          error?.message ||
          "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [enqueueSnackbar, error, updateError]);

  // RENDER
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          bgcolor: "primary.paper"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            my: 2
          }}
        >
          {isNew ? (
            <>
              <Typography sx={{ fontSize: 26, fontWeight: 'bold', textAlign: "center" }}> Add New Scope</Typography>
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 26, fontWeight: 'bold', textAlign: "center" }}> Update Scope Data</Typography>
            </>
          )}
          <Divider />
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required",
              pattern: {
                value: /^\S+$/,
                message: "Scope name should not have a whitespace."
              }
            }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                label="Name"
                fullWidth
                autoComplete="name"
                error={!!errors?.name}
                helperText={errors?.name ? errors.name.message : null}
                size='small'
                disabled={!isNew}
                sx={{
                  mt: 1.5,
                  mb: 0.5
                }}
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: "Description is required",
            }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                label="Description"
                fullWidth
                multiline
                autoComplete="description"
                error={!!errors?.description}
                helperText={errors?.description ? errors.description.message : null}
                size='small'
                sx={{
                  mt: 1.5,
                  mb: 0.5
                }}
                {...field}
              />
            )}
          />
          <Controller
            name="resource_server_name"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                label="Resource Server Name"
                fullWidth
                autoComplete="resource_server_name"
                error={!!errors?.resource_server_name}
                helperText={errors?.resource_server_name ? errors.resource_server_name.message : null}
                size='small'
                select={isNew}
                disabled={!isNew}
                sx={{
                  mt: 1.5,
                  mb: 1.5
                }}
                {...field}
              >
                <MenuItem value="">
                  {"None"}
                </MenuItem>
                {resourceServers?.data?.data?.map((resourceServer) =>
                  <MenuItem key={resourceServer.id} value={resourceServer.name}>
                    {resourceServer.name}
                  </MenuItem>
                )}
              </TextField>
            )}
          />
          <Divider />
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                ":hover": { bgcolor: "#FFAA00" },
                backgroundColor: "#1c2526",
                color: "#fff",
              }}
              onClick={handleModalClose}
            >
              Back
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                bgcolor: "#fdbc3d",
                ":hover": { bgcolor: '#1c2526' }
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <Typography sx={{ color: "#fff" }}>
                {isNew ?
                  'Add' : 'Update'
                }
              </Typography>
              {(isLoading || updateIsLoading) && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "secondary.main",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default ScopesUpdate;