import { Add } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import HasPermission from "../utils/access";

function CustomToolbar(props) {
    return (
        <GridToolbarContainer>
            <Grid container>
                <Grid xs={12} md={7} item>
                    <GridToolbarQuickFilter
                        sx={{ minWidth: '75%', pl: 2 }}
                        autoFocus={props.searchFocus}
                        onFocusCapture={props.handleOnSearchFocus}
                    />
                </Grid>
                <Grid xs={12} md={5} item justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {HasPermission(props?.permission) && (
                            <Button
                                size="small" 
                                sx={{
                                    p: 0.5,
                                    mx: 0.5,
                                    color: '#fff',
                                    bgcolor: '#fdbc3d',
                                    fontSize: 12,
                                    ":hover": { bgcolor: 'black' }
                                }}
                                onClick={props.handleOpen}
                            >
                                <Add />
                                <Typography sx={{display: {xs: "none", md: "block"}}}>
                                    Add {props.page}
                                </Typography>
                            </Button>
                        )}
                        <GridToolbarFilterButton
                            page="ADD FILTERS"
                            sx={{ color: '#fdbc3d' }}                      
                            onFocusCapture={props.handleOnFilterFocus}
                        />
                        <GridToolbarExport sx={{ color: '#fdbc3d' }} />
                    </Box>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}
export default CustomToolbar;