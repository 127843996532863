import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function PrivateRoute({ children }) {
  let { auth } = useAuth();
  let location = useLocation();

  if (!auth.access_token) {
    return <Navigate to="/login" state={{ from: location}} replace />;
  }
  return children;
}
export default PrivateRoute;
