// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { 
    CircularProgress, 
    // Divider, 
    Grid 
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
// import logo from "../../utils/images/ridePlus.png";
import { useEffect } from "react";

function PromptNoneConsent({ consentId, loggedIn }) {
    const axiosPrivate = useAxiosPrivate();

    // Mutation
    const { isLoading, data } = useQuery(["promt_none_consent"], () => {
        return loggedIn ?
            (
                axiosPrivate.get(`/oauth/consent/${consentId}`)
            ) : (
                rejectMutate()
            );
    },
        {
            refetchOnWindowFocus: false,
            retry: false
        }
    );

    const { mutate, isLoading: approvalLoading, data: approvalData } = useMutation(() => {
        return axiosPrivate.post(
            "/oauth/approveConsent",
            {
                consent_id: consentId
            },
            {
                withCredentials: true
            }
        );
    });

    const { mutate: rejectMutate, isLoading: rejectLoading, data: rejectData } = useMutation(() => {
        return axiosPrivate.post(
            "/oauth/rejectConsent",
            {
                consent_id: consentId,
                failure_reason: "Login is required!"
            },
            {
                withCredentials: true
            }
        );
    });

    // USEEFFECT
    useEffect(() => {
        if (data?.data?.data?.approved) {
            mutate();
        }
        if ((data && !(data?.data?.data?.approved)) || !loggedIn) {
            rejectMutate();
        }
    }, [data, loggedIn, mutate, rejectMutate])

    useEffect(() => {
        if (approvalData?.data?.data?.location) {
            window.location.href = approvalData?.data?.data?.location;
        }
    }, [approvalData?.data?.data?.location])

    useEffect(() => {
        if (rejectData?.data?.data?.location) {
            window.location.href = rejectData?.data?.data?.location;
        }
    }, [rejectData?.data?.data?.location])

    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        // boxShadow: 1,
                        p: 2
                    }}
                >
                    {/* <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Avatar
                            size="small"
                            src={logo}
                            sx={{ width: 24, height: 24, justifyContent: "center", mr: 1, borderRadius: 0 }}
                        />
                        <Typography sx={{ display: "inline", fontSize: 14, fontWeight: "bold" }}>Sign In with Ride Plus SSO</Typography>
                    </Box>
                    <Divider /> */}
                    {(isLoading || approvalLoading || rejectLoading) &&
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    minHeight: "40vh"
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "secondary.main",
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            </Box>
                        </>
                    }
                </Box>
            </Container>
        </Grid>
    );
}
export default PromptNoneConsent;