import { Avatar, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import logo from "../utils/images/ridePlus.png";

// Error Page Component
function Error({ error }) {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 1,
            p: 2,
          }}
        >
          <Avatar
            sx={{
              width: 200,
              height: 180,
              borderRadius: 0,
            }}
            alt="logo"
            src={logo}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography
              sx={{
                display: "inline",
                fontSize: 30,
                fontWeight: "bold",
                color: "primary.remove",
              }}
            >
              Error.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 0.5,
              }}
            >
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 30,
                  color: "#9f9b94",
                  mb: 0.5,
                }}
              >
                {error
                  ? `${error?.response?.data?.error?.message}` ||
                  `${error?.message}` ||
                  "Network Error!"
                  : ""}
              </Typography>
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 30,
                  color: "#9f9b94",
                }}
              >
                That is all we know.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}
export default Error;