import { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Modal
} from "@mui/material";
import ResourceServersUpdate from './resource-servers-update';
import CustomToolbar from '../customToolbar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import { useQuery } from 'react-query';
import Error from '../error';

// Resource Servers List Component
function ResourceServers() {
  // USESTATES & USEREFS
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [searchFocus, setSearchFocus] = useState(true);
  const [filterFocus, setFilterFocus] = useState(true);
  const [columnField, setColumnField] = useState();
  const [operatorValue, setOperatorValue] = useState();
  const [values, setValue] = useState();
  const [sortModel, setSortModel] = useState([
    { field: "created_at", sort: "asc" },
  ]);

  // CUSTOM HOOK
  const axiosPrivate = useAxiosPrivate();

  // QUERY & MUTATION   
  const filterOptions = { columnField, operatorValue, values }
  const {
    isLoading: resourceServersDataLoading,
    data: resourceServersData,
    refetch,
    error
  } = useQuery(["resource-servers", page, perPage, filterOptions, sortModel], () => {
    return axiosPrivate.get(`/resourceServers`, {
      params: {
        page: page,
        per_page: perPage,
        sort: JSON.stringify(sortModel),
        filter: (
          filterOptions.columnField === undefined ||
          filterOptions.operatorValue === undefined ||
          filterOptions.values === undefined
        ) ? [] : JSON.stringify([{
          column_field: filterOptions?.columnField,
          operator_value: filterOptions?.operatorValue,
          value: filterOptions?.values
        }])
      },
    });
  },
    {
      refetchOnWindowsFocus: false,
      enable: false
    }
  );

  // EVENT HANDLERS & HELPERS
  const handleDrawer = () => {
    setOpen(false);
  }
  const handleModalClose = () => {
    setOpen(false);
  }
  const handleOnFilterFocus = () => {
    setFilterFocus(true)
    setSearchFocus(false)
  }
  const handleOnSearchFocus = () => {
    setFilterFocus(false)
    setSearchFocus(true)
  }
  const onFilterChange = useCallback((filterModel) => {
    if (filterFocus && filterModel?.items.length !== 0) {
      filterModel.quickFilterValues = [];
      setColumnField(filterModel?.items[0]?.columnField);
      setOperatorValue(filterModel?.items[0]?.operatorValue);
      setValue(filterModel?.items[0]?.value);
    } else {
      filterModel.items = [];
      setColumnField("*");
      setOperatorValue("contains");
      setValue(JSON.stringify({
        columns: ["resource_server_name"],
        value: filterModel?.quickFilterValues[0]
      }));
    }
  }, [filterFocus]);
  const onSortChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  // USEEFFECT 
  useEffect(() => {
    if (values !== undefined) {
      setPage(0);
    }
    if (values?.value !== undefined) {
      if (JSON.parse(values)?.value || values) {
        setPage(0);
      }
    }
  }, [values])

  // DATA STRUCTURE
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'created_at', headerName: 'Created At', type: 'date', flex: 1,
      valueGetter: (params) =>
        moment(params?.row?.created_at).format("llll")
    },
    {
      field: 'scopes', headerName: 'Scopes', flex: 1,
      valueGetter: (params) =>
        params?.row?.scopes?.map(e => e.name),
      filterable: false,
      sortable: false
    }
  ];

  // CUSTOM COMPONENT
  function DataGridCustomToolbar() {
    return (
      <CustomToolbar
        searchFocus={searchFocus}
        handleOpen={() => {
          setOpen(true);
          setSearchFocus(false);
        }}
        handleOnFilterFocus={handleOnFilterFocus}
        handleOnSearchFocus={handleOnSearchFocus}
        page="Resource Server"
        permission="create_resource_server"
      />
    )
  }

  // RENDERING
  return error ? (
    <Error error={error?.response?.data?.error} />
  ) : (
    <Box
      sx={{
        backgroundColor: "#fff",
        flex: 5,
        p: 0
      }}
    >
      <Modal
        open={open}
        children={
          <ResourceServersUpdate
            handleModalClose={handleModalClose}
            refetch={refetch}
          />
        }
        onClose={handleDrawer}
      />
      <DataGrid
        sx={{
          pt: 2,
          px: 2,
          fontSize: 12,
          overflow: 'hidden',
          resize: 'both'
        }}
        rows={resourceServersData?.data?.data || []}
        columns={columns}
        loading={resourceServersDataLoading}
        autoHeight
        pagination
        page={page}
        pageSize={perPage}
        paginationMode="server"
        rowsPerPageOptions={[5, 10, 25, 100]}
        rowCount={resourceServersData?.data?.meta_data?.total}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        sortingMode="server"
        sortModel={sortModel}
        sortingOrder={["desc", "asc"]}
        onSortModelChange={onSortChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        components={{ Toolbar: DataGridCustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 1000 }
          }
        }}
      />
    </Box>
  )
}
export default ResourceServers;