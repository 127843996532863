import { Button, CircularProgress, Container, Divider, FormLabel, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// Users Details View Component
const UsersDetail = ({ handleViewModalClose, userId }) => {
    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // QUERY
    const { isLoading, data, isFetching, error } = useQuery(["users"], () => {
        return axiosPrivate.get(`/users/${userId}`);
    },
        {
            refetchOnWindowFocus: false,
            enable: false
        }
    );

    // Identity Provider Data
    const UserData = [
        {
            label: "First Name:",
            value: data?.data?.data?.first_name
        },
        {
            label: "Middle Name:",
            value: data?.data?.data?.middle_name
        },
        {
            label: "Last Name:",
            value: data?.data?.data?.last_name
        },
        {
            label: "Gender:",
            value: data?.data?.data?.gender
        },
        {
            label: "Email:",
            value: data?.data?.data?.email
        },
        {
            label: "Phone:",
            value: data?.data?.data?.phone
        },
        {
            label: "Role:",
            value: data?.data?.data?.role
        }
    ]
    // USEFFECTS
    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);

    // RENDER
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    bgcolor: "primary.paper",
                    borderRadius: 4
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        my: 3,
                        mx: 2
                    }}
                >
                    <Box sx={{ mb: 1 }}>
                        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
                            View User Details
                        </Typography>
                    </Box>
                    <Divider />
                    {
                        isLoading || isFetching ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 3,
                                    mb: 2
                                }}
                            >
                                <CircularProgress
                                    size={30}
                                    sx={{
                                        color: "secondary.main",
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                {UserData.map((user) =>
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "flex-start", my: 0.5, py: 1 }}>
                                            <FormLabel sx={{ flex: 1 }}>{user.label}</FormLabel>
                                            <Typography
                                                sx={{
                                                    flex: 2,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '2',
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {user.value}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                    </>
                                )}
                            </>
                        )}
                    <Divider />
                    <Box
                        sx={{
                            mt: 1.5,
                            display: 'flex'
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                ":hover": { bgcolor: "#FFAA00" },
                                backgroundColor: "#1c2526",
                                color: "#fff",
                                textTransform: "none"
                            }}
                            onClick={handleViewModalClose}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Container >
        </Grid >
    );
};
export default UsersDetail;